import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InternalTag, ISearchableDataSource } from '@controls/dm-common';
import { SelectOption } from '@controls/dm-select/select.option';
import { ParamsHandler } from '@core/params-handler';
import { Operand } from '@models/operand.model';
import { DefaultParamValue, OperandType, RoleDefaultParam } from '@models/role-default-param.model';
import { User } from '@models/User';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';
import { ContextMenuItem, DynamicMatTableComponent, IRowEvent, PrintConfig, TableField, TablePagination, TableSetting } from 'dynamic-mat-table';
import { BehaviorSubject } from 'rxjs';
import { DcButtonComponent } from '../../dc-button/dc-button.component';

@Component({
  selector: 'app-edit-user-param',
  templateUrl: './edit-user-param.component.html',
  styleUrls: ['./edit-user-param.component.scss'],
})
export class EditUserParamComponent implements OnInit, OnChanges {
  @ViewChild(DynamicMatTableComponent, { static: false })
  table: DynamicMatTableComponent<any>;
  @Input() user: User = null;
  @Output() event = new EventEmitter<any>();
  currentIndex = -1;
  defaultParamID = null;
  // roleList: Role[] = [];
  // currentRole: Role = null;
  currentParamType: any = null;
  currentParamTypeList = [
    {ParamName: 'Global', ParamTypeID: 1},
    {ParamName: 'Service', ParamTypeID: 2}
  ];
  operanList: Operand[] = [];
  filteredOperandList: Operand[] = [];
  currentParam: RoleDefaultParam = null;
  // roleDefaultParam: RoleDefaultParam[] = [];
  globalParamList: RoleDefaultParam[] = [];
  serviceParamList: RoleDefaultParam[] = [];
  selectParamList: RoleDefaultParam[] = [];  /* Whitch Global or Service */
  defaultParamValue: DefaultParamValue[] = [];
  selectedValue: any[] | any = null;
  selectedOperand = null;
  // dataList = []; /* Selected Procedure Data List*/
  currentAccess = null;

  /* Table */
  gridName = 'param_grid';
  fields: TableField<any>[] = [];
  dataSource = new BehaviorSubject([]);
  printConfig: PrintConfig = { title: 'Role Param List' , showParameters: true };
  pagination: TablePagination = {
    pageIndex: 0,
    pageSize: 20,
    pageSizeOptions: [10, 20, 100, 1000, 10000]
  };
  contextMenuItems: ContextMenuItem[] = [
    {
      name: "Edit",
      text: "Edit",
      color: "primary",
      icon: "edit",
      visible: true
    },
    {
      name: "Delete",
      text: "Delete",
      color: "primary",
      icon: "delete",
      visible: true
    }
  ];
  tableSetting: TableSetting= {
    alternativeRowStyle: {'background-color': '#efefef'},
    rowStyle: {'border-bottom': 'solid 1px red;'}
  };

  constructor(private gs: GlobalService, public dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if( this.user?.RolesList) {
      if(typeof this.user.RolesList === 'string') {
        this.user.RolesList = JSON.parse(this.user.RolesList);
      }
      this.onSelectedIndexChange(0);
    } else {
      this.gs.toaster.open({ text: 'this user does not assigned to any application!', type: 'secondary'});
      this.back_onClick();
    }
  }

  ngOnInit() {
    this.fetchOperandList();
    this.initGrid();
  }

  onSelectedIndexChange(index) {
    this.currentIndex = index;
    setTimeout(() => {
      this.fetchUserDefaultParamValue();
    }, 500);
  }

  fetchOperandList() {
    this.currentParam = null;
    ApiRequest('GET')
      .schemaName(null)
      .controller('operand')
      .action('list')
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        this.operanList = resp.Data.Operand;
      });
  }

  getApplicationParameterList(applicationRole: any) {
    ApiRequest('GET')
    .schemaName(null)
    .controller('access')
    .action('defaultparamconfiglist')
    .addParam('ApplicationID', applicationRole.ApplicationID)
    // .addParam('Required', 1)
    // .addParam('DP_TYPE_FLAG', 1)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        applicationRole.ParameterConfigList = resp.Data.Config;
        applicationRole.ParameterConfigList.forEach( param => {
          // param.ApplicationID = applicationRole.ApplicationID;
          param.RoleID = applicationRole.RoleID;
          this.fetchDynamicGet(param);
        });
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  getParametersByType(app) {
    if(this.currentParamType) {
      return app.ParameterConfigList.filter( f => f.DP_TYPE_FLAG === this.currentParamType.ParamTypeID);
    } else {
      return [];
    }
  }

  // fetchRoleDefaultParam() {
  //   this.currentParam = null;
  //   ApiRequest('GET')
  //     .schemaName(null)
  //     .controller('access')
  //     .action('defaultparamconfiglist')
  //     // .addParam('UserID', this.user.UserID)
  //     .addParam('RoleID', this.user.RolesList[this.currentIndex].RoleID)
  //     // .addParam('DP_TYPE_FLAG', this.currentParamType.ParamTypeID)
  //     .call(this.gs)
  //     .subscribe((resp) => {
  //       this.globalParamList = (resp.Data.Config as RoleDefaultParam[]).filter(f => f.DP_TYPE_TITLE === 'GLOBAL');
  //       this.serviceParamList = (resp.Data.Config as RoleDefaultParam[]).filter(f => f.DP_TYPE_TITLE === 'SERVICE');
  //       // this.selectedIndex = 2;
  //     });
  // }

  accessList = [];
  paramType_onChanged(e) {
    this.currentParam = null;
    this.selectedOperand = null;
    this.selectedValue = null;
    if(e.value.ParamTypeID === OperandType.List) {
      this.selectParamList = this.globalParamList;
    } else if(e.value.ParamTypeID === OperandType.Scalar) {
      this.selectParamList = this.serviceParamList;
      this.accessList = [];
      this.selectParamList.forEach( p => {
        if(this.accessList.find( s => s.AccessID === p.AccessID) === undefined) {
          this.accessList.push(p);
        }
      });
    }
  }

  service_onChanged(e) {
    this.currentParam = null;
    this.selectedOperand = null;
    this.selectedValue = null;
    if(e.value.ParamTypeID === 1) {
      this.selectParamList = this.globalParamList;
    } else if(e.value.ParamTypeID === 2) {
      this.selectParamList = this.serviceParamList;
    }
  }

  back_onClick() {
    this.event.emit({ selectedIndex: 0, refresh: true});
  }

  initGrid() {
      this.fields = [
        {
          name: 'ParameterType',
          header: 'Global/Service',
          width: 180,
        },
        {
          name: 'FieldName',
          header: 'Parameter Name',
          width: 220,
        },
        {
          name: 'OperandType',
          header: 'Operand',
          width: 130,
        },
        {
          name: 'ValueText',
          header: 'Value',
          width: 400,
        },
        {
          name: 'Opertion',
          header: 'Opertion',
          option: [{name: 'Delete', icon: 'delete', color: 'accent'}],
          dynamicCellComponent: DcButtonComponent,
          width: 240,
          sticky: 'end'
        }
      ];
  }

  rowTable_onEvent(e: IRowEvent) {

    if(e.event === 'ButtonClick' && e.sender.name === 'Edit') {

    } else if(e.event === 'ButtonClick' && e.sender.name === 'Delete') {
      Global.Confirmation(this.dialog, "You are about to delete a parameter, Are you sure?").afterClosed().subscribe( reslut =>{
        if(reslut === 'Yes') {
          const defaultParamID = this.dataSource.value.find( (_, id) => id == e.sender.row)?.DefaultParamID;
          if(defaultParamID) {
            this.deleteDefaultParam(defaultParamID);
          }
          const data = this.dataSource.value.filter( (_, id) => id !== e.sender.row);
          this.dataSource.next([...data]);
        }
      });
    }
  }

  addParam_onClick(app) {
    if(this.currentParamType.ParamTypeID === 2 && this.currentAccess === null) {
      this.gs.toaster.open({ text: 'Service not selected!' , type: 'warning' });
      return;
    }
    if( this.currentParam.OperandType === 1 && this.currentParam.SelectOption.value.length === 0) {
      this.gs.toaster.open({ text: 'Value not set!' , type: 'warning' });
      return;
    }
    if( this.selectedOperand === null) {
      this.gs.toaster.open({ text: 'Operand not selected.' , type: 'warning' });
      return;
    }
    if( this.currentParam.OperandType === 2 && this.selectedValue === null || this.selectedValue?.length === 0) {
      this.gs.toaster.open({ text: 'Value not set!' , type: 'warning' });
      return;
    }
    const selectedValue = (this.currentParam.OperandType === 1 ? JSON.stringify(this.currentParam.SelectOption.value.map( i => i.key)) :  this.selectedValue);
    const selectedText = (this.currentParam.OperandType === 1 ? this.currentParam.SelectOption.value.map( i => i.text).join(',') :  this.selectedValue);
    const parameterValue = {
      DefaultParamID: this.defaultParamID,
      UserID: this.user.UserID,
      RoleID: this.user.RolesList[this.currentIndex].RoleID,
      AccessID: null,
      ParameterID: this.currentParam.ParameterID,
      OperandID: this.selectedOperand,
      DefaultParamValue: selectedValue,
      DP_TYPE_FLAG: 1, /* Global */
      /* Show on Table */
      ParameterType: 'Global',
      FieldName: this.currentParam.ParameterDesc,
      OperandType:  this.operanList.find( o => o.OperandID === this.selectedOperand)?.OperandAction,
      ValueText: selectedText
    };

    if(this.currentParamType?.ParamName === 'Service') {
      parameterValue.AccessID = this.currentAccess.AccessID;
      parameterValue.DP_TYPE_FLAG = 2; // Serviec
      parameterValue.ParameterType = 'Global';
    }
    const params = this.dataSource.value;
    params.push(parameterValue);
    this.dataSource.next(params);
    this.table.refreshUI();
    /* Reset Input */
    this.currentParamType = null;
    this.currentAccess = null;
    this.currentParam = null;
    this.selectedOperand = null;
    this.selectedValue = null;
  }

  /*  0 = GLOBAL , 1 = SRVICE BASE */
  saveAllParam_onClick() {
    this.dataSource.value.forEach( row => {
      const param = {
        UserID: row.UserID,
        RoleID: row.RoleID,
        ParameterID: row.ParameterID,
        OperandID: row.OperandID,
        DefaultParamValue: row.DefaultParamValue,
        DP_TYPE_FLAG: row.DP_TYPE_FLAG,
      };
      if(row.DefaultParamID) {
        param['DefaultParamID'] = row.DefaultParamID;
      }
      if(row.AccessID) {
        param['AccessID'] = row.AccessID;
      }

      ApiRequest('POST')
      .schemaName(null)
      .controller('access')
      .action('defaultparamedit')
      .setBody(new ParamsHandler(param))
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        if (resp.Success == true) {
          // this.fetchRoleDefaultParam();
          this.gs.toaster.open({type: 'success', text: 'Saved Successfully'});
        } else {
          this.gs.toaster.open({type: 'danger', text: resp.Message });
        }
      });
    });
  }

  deleteDefaultParam(defaultParamID) {
    if(defaultParamID) {
      ApiRequest('POST')
        .schemaName(null)
        .controller('access')
        .action('defaultparamdelete')
        .addBody('DefaultParamID', defaultParamID)
        .call(this.gs)
        .subscribe((resp) => {
          resp = normalizeResponse(resp);
          if (resp.Success == true) {
            // this.fetchRoleDefaultParam();
            this.gs.toaster.open({type: 'success', text: 'Saved Successfully'});
          } else {
            this.gs.toaster.open({type: 'danger', text: resp.Message });
          }
        });
    }
  }

  initOption(param) {
    let list = param.DataList.map( item => {
      return { key: item[param.ParameterName], text: item[param.ParameterLabel], tag: item };
    });
    const paramValueOption: SelectOption = {
      name: 'paramValue',
      label: param.ParameterDesc,
      multiple: true,
      tagName: InternalTag.Select,
      appearance: 'fill',
    };
    const ds: ISearchableDataSource<any> =
    {
      displayFieldName: 'text',
      keyFieldName:'key',
      callbackSearch: (search, dataList: any[], parent: any) => {
        const filterData = dataList.filter( f =>
          f.text.includes(search) || paramValueOption?.value?.find( item => item === f));
        console.log(filterData);
        return filterData;
      },
      list: list
    };
    paramValueOption.list = ds;
    if(paramValueOption.updateModel) {
      paramValueOption.updateModel();
    }
    return paramValueOption;
  }


  param_onChanged(e) {
    this.selectedOperand = null;
    this.selectedValue = null;
    this.filteredOperandList = this.operanList.filter( o => o.OperandType === this.currentParam.OperandType);
    this.fetchDynamicGet(this.currentParam, null);
  }

  fetchDynamicGet(defaultParam: RoleDefaultParam, search = null) {
    if(defaultParam.OperandType === OperandType.List) {
      defaultParam.DataList = [];
      defaultParam.SelectOption = this.initOption(defaultParam);
      const actionController = defaultParam.ParameterAction.toLowerCase().split('/');
      ApiRequest('GET')
        .schemaName(null)
        .controller(actionController[0])
        .action(actionController[1])
        .addParam(defaultParam.ParameterName, search)
        .addParam('PageIndex', 0)
        .addParam('PageSize', 1000)
        .call(this.gs)
        .subscribe((resp) => {
          resp = normalizeResponse(resp);
          // this.dataList = resp.Data[defaultParam.ParameterDatasetName];
          defaultParam.DataList = [];
          defaultParam.DataList = resp.Data[defaultParam.ParameterDatasetName];
          defaultParam.SelectOption.list.list = defaultParam.DataList.map( item => {
            return { key: item[defaultParam.ParameterName], text: item[defaultParam.ParameterLabel], tag: item };
          });
          /* Select Parameter to set values */
          const param = this.globalValueList.find( f => f.ApplicationID === defaultParam.ApplicationID);
          if(param) {
            defaultParam.SelectOption.value = defaultParam.SelectOption.list.list.filter( item => param.DefaultParamValue.find( v => v === item.key) );
          }
          if(defaultParam.SelectOption.updateModel) {
            defaultParam.SelectOption.updateModel();
          }
        });
    }
  }

  serviceValueList = [];
  globalValueList = [];
  pending = true;
  fetchUserDefaultParamValue() {
    console.log('fetchUserDefaultParamValue');
    this.pending = true;
    ApiRequest('GET')
      .schemaName(null)
      .controller('access')
      .action('userdefaultparamlist')
      .addParam('ApplicationID', this.user.RolesList[this.currentIndex].ApplicationID)
      .addParam('UserID', this.user.UserID)
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        this.pending = false;
        const data = [];
        this.serviceValueList = resp.Data.ServiceBased;
        this.globalValueList = resp.Data.Global;
        this.globalValueList.forEach( g => g.DefaultParamValue = JSON.parse(g.DefaultParamValue));
        this.user.RolesList.forEach( role => {
          this.getApplicationParameterList(role);
        });

        /* Add to Grid */
        this.globalValueList.forEach( item => {
          let parameterValue: any = {
            DefaultParamID: item.ApplicationDefaultParameterID,
            UserID: this.user.UserID,
            RoleID: item.RoleID,
            AccessID: null,
            ParameterID: item.ApplicationParameterID,
            OperandID: item.OperandID,
            DefaultParamValue: item.DefaultParamValue,
            DP_TYPE_FLAG: 1, /* Global */
            /* Show on Table */
            ParameterType: 'Global',
            FieldName: item.ParameterDesc,
            OperandType:  this.operanList.find( o => o.OperandID === item.OperandID)?.OperandAction,
            ValueText: ''
          };
          this.fetchDynamicDisplay(item, parameterValue);
          data.push(parameterValue);
        });
        this.serviceValueList.forEach( item => {
          let parameterValue: any = {
            DefaultParamID: item.ApplicationDefaultParameterID,
            UserID: this.user.UserID,
            RoleID: item.RoleID,
            AccessID: item.AccessID,
            ParameterID: item.ApplicationParameterID,
            OperandID: item.OperandID,
            DefaultParamValue: item.DefaultParamValue,
            DP_TYPE_FLAG: 2, /* Serviec */
            /* Show on Table */
            ParameterType: 'Service',
            FieldName: item.ParameterDesc,
            OperandType:  this.operanList.find( o => o.OperandID === item.OperandID)?.OperandAction,
            ValueText: ''
          };
          this.fetchDynamicDisplay(item, parameterValue);
          data.push(parameterValue);
        });
        this.dataSource.next(data);
      });
  }

  /* Todo Complate get display name */
  fetchDynamicDisplay(param: any, valuePointer: any) {
    console.log('fetchDynamicDisplay');
    if(this.operanList.find( o => o.OperandID === param.OperandID).OperandType === OperandType.List) {
      const actionController = param.ParameterAction.toLowerCase().split('/');
      ApiRequest('GET')
        .schemaName(null)
        .controller(actionController[0])
        .action(actionController[1])
        .addParam(param.ParamFilterField, JSON.stringify(param.DefaultParamValue))
        .addParam('PageIndex', 0)
        .addParam('PageSize', 1000)
        .call(this.gs)
        .subscribe((resp) => {
          resp = normalizeResponse(resp);
          const data = resp.Data[param.ParameterDatasetName].map(item => {
            return { key: item[param.ParameterName], text: item[param.ParameterLabel], tag: item };
          });
          valuePointer.ValueText = data.map( i => i.text).join(',');
        });
    }
  }

}

