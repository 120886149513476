import { Pipe, PipeTransform } from '@angular/core';
import { Lib } from '@core/lib';

@Pipe({
  name: 'itineraryStatus'
})
export class ItineraryStatusPipe implements PipeTransform {
  transform(value: number): string {
    if ( value <= 1.5) {
      return 'Bad';
    } else if ( value > 1.5) {
      return 'Good';
    }
  }
}
