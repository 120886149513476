import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmTreeComponent } from './dm-tree.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { TreeNodeDefDirective } from './directives/tree-node-def.directive';
import { TreeLeafNodeDefDirective } from './directives/tree-leaf-node-def.directive';
import { TreeEditNodeDefDirective } from './directives/tree-edit-node-def.directive';
import { TreeNewNodeDefDirective } from './directives/tree-new-node-def.directive';
import { TreeCustomNodeDefDirective } from './directives/tree-custom-node-def.directive';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    MatTreeModule,
    CdkTreeModule,
  ],
  declarations: [
    DmTreeComponent,
    TreeNodeDefDirective,
    TreeLeafNodeDefDirective,
    TreeEditNodeDefDirective,
    TreeNewNodeDefDirective,
    TreeCustomNodeDefDirective,
  ],
  exports: [
    DmTreeComponent,
    TreeNodeDefDirective,
    TreeLeafNodeDefDirective,
    TreeEditNodeDefDirective,
    TreeNewNodeDefDirective,
    TreeCustomNodeDefDirective,
  ],
})
export class DmTreeModule {}
