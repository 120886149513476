<mat-progress-bar *ngIf="loading" class="loading-bar" mode="indeterminate"></mat-progress-bar>
<mat-toolbar id="toolbar" class="main-toolbar">
  <button *ngIf="sidNavActive" mat-icon-button class="sidenav-toggle" (click)="toggleSidenav.emit()">
    <mat-icon>menu</mat-icon>
  </button>

  <div id="menuContainer">
    <a @items *ngFor="let menu of menuItems ;let i = index"  mat-button matLine [class.selected-menu]="menu?.selected" (click)="menu_OnClick(menu)" class="button-item">
      <mat-icon *ngIf="menu.fontIcon">{{menu.fontIcon}}</mat-icon>
      <img class="toolbar-icon" *ngIf="menu.icon" src={{menu.icon}}>
      <span class="link-white">{{menu.name}}</span>
    </a>
  </div>
  <div id="spacer" class="toolbar-spacer" (window:resize)="updateVisibleMenu()"></div>

  <button mat-icon-button class="profile-button" [matMenuTriggerFor]="profile"  [class.selected-menu]="isProfileSelected()">
    <div mat-card-avatar class="profile-image"></div>
  </button>

  <mat-menu  #profile="matMenu" class="profile-menu" >
    <i class="material-icons menu-arrow">arrow_drop_up</i>

    <div class="profile-header" (click)="$event.stopPropagation()">
      <div class="user-img-span">
        <div class="avatar" style="color:white; background: #009688">{{ firstLetterName()}}</div>
      </div>
      <div class="user-info">
        <div class="profile-name">
          <span class="guest">{{ loginResult ? loginResult.UserName : 'Guest'}}</span>
        </div>
        <!-- <div class="user-name">
          <span>{{ loginResult ? loginResult?.RoleName : 'Guest'}}</span>
        </div> -->
        <div class="user-profile">
          <button mat-flat-button color="primary" (click)="showProfile()" class="profile-view">Profile</button>
          <button mat-flat-button color="transparency" (click)="userLogout()" class="log-out">Log Out</button>
        </div>
        <div class="server-time">
          <span>Server Time:</span> <span class="time">{{(st.timeAsObservable | async) | date:'hh:mm:ss aaa'}}</span>
        </div>
      </div>
    </div>


    <div class="profile-footer">

      <ul class="button-list">
          <li *ngFor="let menu of actionButtonList" [matTooltip]="menu.name">
              <button mat-flat-button  (click)="menu_OnClick(menu)">
                  <img [src]="menu.icon">
                  <span>{{menu.name}}</span>
              </button>
          </li>
      </ul>
    </div>
  </mat-menu>
</mat-toolbar>

<div class="splitter"></div>

