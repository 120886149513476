export class TreeNode<T = any> {    
    name: string;
    data?: T;
    type?: NodeDefType = NodeDefType.NormalTemplate;
    children?: TreeNode<T>[] | any[];
    [index:string] : any; //{message: string};
}


export enum NodeDefType {
    NormalTemplate = 1,
    LeafTemplate = 2,
    NewTemplate = 3,
    EditTemplate = 4,
    CustomTemplate = 5,
}