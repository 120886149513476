import { Component, ViewChild } from '@angular/core';
import { DmTreeComponent } from '@controls/dm-tree/dm-tree.component';
import { FlatNode } from '@controls/dm-tree/models/flat-node.model';
import { TreeEvent } from '@controls/dm-tree/models/tree-event.model';
import { NodeDefType, TreeNode } from '@controls/dm-tree/models/tree-node.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'reporter';
  @ViewChild(DmTreeComponent, { static: false }) treeControl: DmTreeComponent;
  dataSource = [
    {
      label: "Parent 1",
      children: [{ label: "Child 1" }, { label: "Child 2" }]
    },
    { label: "Parent 2" }
  ];  

  dataList: any[] = [
    {
      name: 'Fruit',
      children: [
        {name: 'Apple', type: NodeDefType.LeafTemplate},
        {name: 'Banana', type: NodeDefType.LeafTemplate},
        {name: 'Fruit loops', type: NodeDefType.LeafTemplate},
      ]
    }, {
      name: 'Vegetables',
      children: [
        {
          name: 'Green',
          children: [
            {name: 'Broccoli', type: NodeDefType.LeafTemplate},
            {name: 'Brussels sprouts', type: NodeDefType.LeafTemplate},
          ]
        }, {
          name: 'Orange',
          children: [
            {name: 'Pumpkins', type: NodeDefType.LeafTemplate},
            {name: 'Carrots', type: NodeDefType.LeafTemplate},
          ]
        },
      ]
    },
  ];

  test(node, isChecked) {
    console.log(node, isChecked);
  }

  tree_onEvent(e: TreeEvent) {
    console.log(e);
  }

  addNewItem(flatNode: FlatNode) {    
    console.log(flatNode);
    const newNode: TreeNode = {
      name: 'New Node',
      type: NodeDefType.NewTemplate
    };
    this.treeControl.addNode(flatNode,newNode);
    // node.children.push(newNode);    
  }

  editItem(flatNode: FlatNode) {
    flatNode.name = 'Edit';
    flatNode.type = NodeDefType.EditTemplate;
  }

  deleteItem(flatNode: FlatNode) {
    this.treeControl.deleteNode(flatNode);
  }

  btn_onClick() {
    const newNode: TreeNode = {
      name: 'New Node',
      type: NodeDefType.NewTemplate,
      children: []
    };
    this.treeControl.addNode(null , newNode);
  }
  
}
