import { Component, OnInit, ViewChild } from '@angular/core';
import { DmTreeComponent } from '@controls/dm-tree/dm-tree.component';
import { FlatNode } from '@controls/dm-tree/models/flat-node.model';
import { TreeEvent } from '@controls/dm-tree/models/tree-event.model';
import { NodeDefType, TreeNode } from '@controls/dm-tree/models/tree-node.model';

@Component({
  selector: 'app-upload-download-file',
  templateUrl: './upload-download-file.component.html',
  styleUrls: ['./upload-download-file.component.scss']
})
export class UploadDownloadFileComponent implements OnInit {
  @ViewChild(DmTreeComponent, { static: false }) treeControl: DmTreeComponent;

  constructor() { }

  ngOnInit() {
  }

  
  dataList: any[] = [
    {
      name: 'Fruit',
      children: [
        {name: 'Apple', type: NodeDefType.LeafTemplate},
        {name: 'Banana', type: NodeDefType.LeafTemplate},
        {name: 'Fruit loops', type: NodeDefType.LeafTemplate},
      ]
    }, {
      name: 'Vegetables',
      children: [
        {
          name: 'Green',
          children: [
            {name: 'Broccoli', type: NodeDefType.LeafTemplate},
            {name: 'Brussels sprouts', type: NodeDefType.LeafTemplate},
          ]
        }, {
          name: 'Orange',
          children: [
            {name: 'Pumpkins', type: NodeDefType.LeafTemplate},
            {name: 'Carrots', type: NodeDefType.LeafTemplate},
          ]
        },
      ]
    },
  ];

  test(node, isChecked) {
    console.log(node, isChecked);
  }

  tree_onEvent(e: TreeEvent) {
    console.log(e);
  }

  addNode_onClick(flatNode: FlatNode) {
    console.log(flatNode);
    const newNode: TreeNode = {
      name: 'New Node',
      type: NodeDefType.EditTemplate
    };
    this.treeControl.addNode(flatNode,newNode);
  }
  
  editNode_onClick(flatNode: FlatNode) {
    console.log(flatNode);
    const newNode: TreeNode = {
      name: 'New Node',
      type: NodeDefType.EditTemplate
    };
    this.treeControl.addNode(flatNode,newNode);
  }
}
