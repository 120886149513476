import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { Output, ViewChild } from "@angular/core";
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";
import { FlatNode } from "./models/flat-node.model";
import { NodeDefType, TreeNode } from "./models/tree-node.model";

export class BaseComponent {

    private _transformer = (node: TreeNode<any>, level: number) => {
        return {
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            type: node.type || NodeDefType.NormalTemplate,
            data: node,
            level: level,
        };
    }
    tree: MatTree<any>;
    treeControl = new FlatTreeControl<FlatNode>(node => node.level, node => node.expandable);
    treeFlattener = new MatTreeFlattener(this._transformer, (node: any) => node.level, node => node.expandable, node => node.children);
    treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    hasChild = (_: number, node: FlatNode) => node.expandable;
    getLevel = (node: FlatNode) => node.level;
    isExpandable = (node: FlatNode) => node.expandable;

    /* ********* General Method ********* */

    getChildren = (node: TreeNode<any>): TreeNode<any>[] => node.children;

    getParentNode(node: FlatNode): FlatNode | null {
        const currentLevel = this.getLevel(node);
        if (currentLevel < 1) {
            return null;
        }
        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];
            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    /* ********* Node Selection ********* */

    nodeSelection = new SelectionModel<FlatNode>(true /* multiple */);

    nodeSelectionToggle(node: FlatNode): void {
        console.log('selection change');
        this.nodeSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

     /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: FlatNode): void {
        let parent: FlatNode | null = this.getParentNode(node);
        while (parent) {
        this.checkRootNodeSelection(parent);
        parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: FlatNode): void {
        const nodeSelected = this.nodeSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.nodeSelection.isSelected(child);
        });
        if (nodeSelected && !descAllSelected) {
            this.nodeSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.nodeSelection.select(node);
        }
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.nodeSelection.isSelected(child);
        });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: FlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.nodeSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /* ********* Node (Add, Delete, Edit) ********* */
    public addNode(flatNode: FlatNode, newNode: TreeNode) {
        if(flatNode === null) {
            this.treeDataSource.data.push(newNode);
            this.treeDataSource.data = [...this.treeDataSource.data];
        } else {
            flatNode.data.children.push(newNode);
            this.treeDataSource.data = [...this.treeDataSource.data];
            const node = this.treeControl.dataNodes.find( n => n.data === flatNode.data);
            node.expandable = true;
            this.treeControl.expand(node);
        }
    }

    public deleteNode(flatNode: FlatNode) {
        const parent = this.getParentNode(flatNode);
        parent.data.children = parent.data.children.filter( d => d === 1 );
        const data = this.treeDataSource.data;
    }

    public editNode(flatNode: FlatNode) {
        flatNode.data.type = NodeDefType.EditTemplate;
        flatNode.type = NodeDefType.EditTemplate;
        const data = this.treeDataSource.data;
        this.treeDataSource.data = [...data];
        // this.treeControl.expandAll();
    }


}
