import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@controls/dialogs/confirmation-dialog/confirmation-dialog.component';
import { environment } from 'src/environments/environment';

export class Global {

  public static currentAccount : any = null;
  public static otpMenuItem: any[] = [
    {
      id: 0,
      name: 'Account List',
      link: '/pages/dashboard',
      fontIcon: 'people',
      icon: null,
      selected: false
    },
    {
      id: 1,
      name: 'Upload/Download Files ',
      link: '/pages/upload-download',
      fontIcon: 'publish',
      icon: null,
      selected: false
    },
    {
      id: 2,
      name: 'Account Information',
      link: '/pages/account-management',
      fontIcon: 'text_format',
      icon: null,
      selected: false
    },
    {
      id: 3,
      name: 'Service Area',
      link: '/pages/service_area',
      fontIcon: 'map',
      icon: null,
      selected: false
    },
    {
      id: 4,
      name: 'Language Pack',
      link: '/pages/language_pack',
      fontIcon: 'language',
      icon: null,
      selected: false
    },
    {
      id: 5,
      name: 'Call Center',
      link: '/pages/call_center',
      fontIcon: '',
      icon: './assets/images/toolbar-icon/callcenter.png',
      selected: false
    },
    {
      id: 6,
      name: 'Scheduler',
      link: '/pages/scheduler',
      fontIcon: '',
      icon: './assets/images/toolbar-icon/scheduler.png',
      selected: false
    },
    {
      id: 6,
      name: 'Driver',
      link: '/pages/driver',
      fontIcon: '',
      icon: './assets/images/toolbar-icon/driver.png',
      selected: false
    },
    {
      id: 7,
      name: 'Customer',
      link: '/pages/customer',
      fontIcon: 'folder_shared',
      icon: null,
      selected: false
    },
    {
      id: 7,
      name: 'AAA',
      link: '/pages/aaa',
      fontIcon: 'group',
      icon: null,
      selected: false
    },
  ];

  public static dayList: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  public static monthNames: string[] = [ 'January', 'February',
  'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December'];

  public static mapColors = ['#b71c1c', '#F57F17', '#004D40', '#00B8D4', '#1A237E', '#0D47A1', '#01579B', '#006064', '#004D40',
   '#1B5E20', '#33691E', '#827717', '#F57F17', '#FF6F00', '#E65100', '#BF360C', '#3E2723', '#212121', '#880E4F'];

   public static GetColor(index: number) {
    return this.mapColors[index % this.mapColors.length];
  }

  public static GetMonthName(index: number) {
    return this.monthNames[index];
  }

  public static GetWeekDay(index: number) {
    return this.dayList[index - 1];
  }

  public static formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  public static Confirmation(dialog: MatDialog, caption: string, customButton: {name: string, text: string} = null) {
    const btnList = [ {name: 'Yes', text: 'Yes'}, {name: 'No', text: 'No'}];
    if (customButton !== null) {
      btnList.push(customButton);
    }
    const dialogRef = dialog.open(ConfirmationDialogComponent, {
      // hasBackdrop: false,
      // position: {bottom: '15px'},
      autoFocus: true,
      width: '60%',
      position: {bottom: '0px'},
      data: {message: caption , buttonList: btnList}
    });
    return dialogRef;
  }
}



