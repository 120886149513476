import { isUndefined } from 'util';

export class ParamsHandler {
    private params: object = {};
    constructor(private init: object = null) {
        if (init !== null) {
           this.params = Object.assign( {} , init);
        }
    }

    public static eup(object: any): string {
      let url = '';
      url = JSON.stringify(object, (key, value) => {
        if (value !== null) {
          return value;
        }
      });
      return btoa(url);
    }

    public static dup<T>(url: string): T {
        return JSON.parse(atob(url));
    }

    public clear() {
        delete this.params;
        this.params = {};
    }

    public addParam(key: any, value: any): ParamsHandler {
        if ( !isUndefined(value) ) {
            this.params[key] = value;
        }
        return this;
    }

    public getParams(): object {
        return this.params;
    }

    public count() {
        if ( this.params ) {
            const objPropName = Object.getOwnPropertyNames(this.params);
            return objPropName.length;
        } else {
            return 0;
        }
    }

    public toJson(): object {
        return this.params;
    }

    public get urlParamaters(): string {
        const objPropName = Object.getOwnPropertyNames(this.params);
        let objStr = '';
        for (const item of objPropName) {
            if (this.params[item] !== '' && this.params[item] !== null && this.params[item] !== undefined) {
                objStr += item + '=' + encodeURIComponent(this.params[item]) + '&';
            }
        }
        return objStr.substring(0, objStr.length - 1);
    }
}
