<mat-card style="padding-bottom: 0;">
  <h1>Role Information</h1>
  <div style="min-height: calc( 100vh - 257px); overflow-y: auto;">
    <mat-form-field appearance="fill">
      <mat-label>Application</mat-label>
      <mat-select [(ngModel)]="role.ApplicationID">
        <mat-option *ngFor="let app of applicationList" [value]="app.ApplicationID">
          {{app.ApplicationName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill">
      <mat-label>Role Name</mat-label>
      <input matInput placeholder="Role Name" [(ngModel)]="role.RoleName">
    </mat-form-field>
    <br>    
    <mat-checkbox [(ngModel)]="role.RoleStatus">
      Active
    </mat-checkbox>
  </div>

  <div class="parameter-action">
    <button mat-raised-button (click)="goBack_onClick()">Back</button>    
    <button mat-raised-button color="primary" (click)="save_onClick()">Save</button>
  </div>
</mat-card>