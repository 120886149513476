import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DynamicMatTableComponent, IDynamicCell, IRowEvent, TableField, TableRow } from 'dynamic-mat-table';

@Component({
  selector: 'app-dc-button',
  templateUrl: './dc-button.component.html',
  styleUrls: ['./dc-button.component.scss']
})
export class DcButtonComponent implements OnInit,IDynamicCell {
  @Input() row: TableRow;
  @Input() column: TableField<any>;
  @Input() parent: DynamicMatTableComponent<any>;
  @Input() onRowEvent?: EventEmitter<IRowEvent>;

  constructor() { }

  ngOnInit() {
  }

  onClick(name: string) {
    this.onRowEvent.emit({
      event: 'ButtonClick',
      sender: {parent: this, name:  name, row: this.row.id }
    });
  }

  button_onClick(btn) {
    this.onRowEvent.emit({
      event: 'ButtonClick',
      sender: {parent: this, name:  btn.name, row: this.row.id }
    });
  }

}
