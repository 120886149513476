import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { BaseFieldDirective, deepClone } from '@controls/dm-common';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectOption } from './select.option';

@Component({
  selector: 'dm-select',
  templateUrl: './dm-select.component.html',
  styleUrls: ['./dm-select.component.scss']
})
export class DmSelectComponent extends BaseFieldDirective<SelectOption>  implements OnInit {
  searchControl: FormControl = new FormControl();
  @ViewChild('search', {static: false}) search: ElementRef;
  public itemSelection: SelectionModel<any>;
  originalList: any[] = [];
  loading$ = new BehaviorSubject<boolean>(true);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.searchControl.valueChanges
    .pipe(
        debounceTime(200),
        distinctUntilChanged()
    ).subscribe(res => {
      if (this.option.list.callbackSearch === null || this.option.list.callbackSearch === undefined) {
        this.option.loading = true;
        this.originalList = this.option.list.list.filter( item => {
          const search = item[this.option.list.displayFieldName];
          return search.indexOf(res) >= 0 || ( this.formControl.value !== undefined && this.formControl.value.includes(item));
        });
      } else {
        this.originalList = this.option.list.callbackSearch(res, this.option.list.list, this );
      }
      this.loading$.next(false);
    });
    this.loading$.pipe(debounceTime(200)).subscribe( l => this.option.loading = l);
    this.loading$.next(false);
  }

  onOptionChanged(option: SelectOption): void {
    super.onOptionChanged(option);
    if (option.ioStatus === null || option.ioStatus === undefined) {
      option.ioStatus = 'input';
    }
    if (this.itemSelection === undefined || this.itemSelection.isMultipleSelection() !== this.option.multiple) {
      this.itemSelection = new SelectionModel<any>(this.option.multiple, deepClone(this.option.value));
    }
    if (this.event === undefined || this.itemSelection.isMultipleSelection() !== this.option.multiple) {
      this.itemSelection = new SelectionModel<any>(this.option.multiple, deepClone(this.option.value));
    }
    if (this.option.list) {
      this.originalList = this.option.list.list || [];
    }
  }

  onOpen(isOpened: boolean, searchControl) {
    if (isOpened === true) {
      window.requestAnimationFrame( () => {
        searchControl.focus();
      });
    }
  }

  isAllSelected() {
    const numSelected = this.itemSelection.selected.length;
    const numRows = this.option.list.list.length;
    return numSelected === numRows;
  }

  selectAll_onClick() {
    this.formControl.patchValue([...this.option.list.list]);
    this.onChange();
  }

  deselectAll_onClick() {
    this.formControl.patchValue([]);
    this.onChange();
  }

  clearAll_onClick() {
    this.option.list.list = [];
    this.onChange();
  }

  masterToggle() {
    this.isAllSelected() ?
    this.itemSelection.clear() :
    this.option.list.list.forEach(row => this.itemSelection.select(row));
    this.onChange();
  }

  onSelectionChange(e: MatSelectChange) {
    this.itemSelection.clear();
    if (Array.isArray(e.value) === true) {
      e.value.forEach( item => {
        this.itemSelection.select(item);
      });
    }
    this.onChange();
  }

  onChange() {
    this.event.emit(
      {
        sender: this.option,
        value: this.formControl.value,
        event: 'change'
      });
  }

  onKeyUp(e) {
  }

}
