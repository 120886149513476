<!-- <div class="full top-bg"></div> -->
<mat-spinner style="margin: 200px auto;z-index: 1;display: block;" fxFlex="100%" *ngIf="checkLinkProgress"
  diameter="50"></mat-spinner>

<div [hidden]="checkLinkProgress">
  <div *ngIf="checkpass" class="reset mat-elevation-z10">
    <div fxFlex="100%">
      <mat-toolbar color="primary" class="toolbar">
        <mat-toolbar-row>
          <span>Change Password</span>
          <span class="spacer"></span>
          <mat-icon class="icon">verified_user</mat-icon>
        </mat-toolbar-row>
      </mat-toolbar>

      <div class="form">
        <label>Your password must contain at least one number, one uppercase and lowercase letter, one special
          characters
          and at least 8 characters. </label>

        <mat-form-field fxFlex="100%" class="full-width">
          <input (input)="truePass(passwordRef.value,confirmPasswordRef.value)"
            (keydown)="input_onKeyDown($event,passwordRef.value,confirmPasswordRef.value)" required matInput="password"
            placeholder="Your new password" type="Password" #passwordRef>
          <mat-hint>required</mat-hint>

        </mat-form-field>
        <div class="trueLabel">
          <div id="truePass" class="trigger"></div>
          <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 37 37" style="enable-background:new 0 0 37 37;" xml:space="preserve">
            <path class="circ path"
              style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
              d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z" />
            <polyline class="tick path"
              style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" points="
	11.6,20 15.9,24.2 26.4,13.8 " />
          </svg>
        </div>
        <mat-form-field fxFlex="100%" class="full-width">
          <input (input)="trueConfirm(passwordRef.value,confirmPasswordRef.value)"
            (keydown)="input_onKeyDown($event,passwordRef.value,confirmPasswordRef.value)" required matInput="password"
            placeholder="Confirm your new password" type="Password" #confirmPasswordRef>
          <mat-hint>required</mat-hint>

        </mat-form-field>

        <div class="trueLabel">
          <div id="trueConfirm" class="trigger"></div>
          <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 37 37" style="enable-background:new 0 0 37 37;" xml:space="preserve">
            <path class="circ path"
              style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
              d="
	M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z" />
            <polyline class="tick path"
              style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" points="
	11.6,20 15.9,24.2 26.4,13.8 " />
          </svg>
        </div>

        <label id="passLabel"></label>

        <div class="pos">

          <button style="float: left;background-color: #7398c5;" fxFlex="50%" mat-raised-button color="primary"
            #loginInput (click)="NewPass(passwordRef.value,confirmPasswordRef.value)">Submit</button>
          <mat-spinner style="float: right;" fxFlex="50%" *ngIf="ResetPassProcess" diameter="40"></mat-spinner>
        </div>

      </div>
    </div>
  </div>
  <div *ngIf="!checkpass" class="errorBox mat-elevation-z10">
    <div fxFlex="100%">

      <div class="box">
        Oops!
        <strong>{{errorTxt}}</strong>
      </div>
    </div>
  </div>
</div>