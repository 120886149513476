<mat-card *ngIf="withToken === true" class="login mat-elevation-z10 login-card">
  <h3>User is verifing and application is loading</h3>
  <img src="../../../assets/images/user-verifing.gif" alt="" style="width: 100%;">
</mat-card>
<mat-card class="login mat-elevation-z10" *ngIf="selectedTab != null && withToken === false">
  <mat-tab-group [selectedIndex]="selectedTab" class="tabs">

    <!-- OTP -->
      <mat-tab >
          <div fxFlex="100%">
            <mat-toolbar class="toolbar">
              <mat-toolbar-row>
                <span>OTP Key</span>
                <span class="spacer"></span>
                <mat-icon class="icon" title="verified_user" >fingerprint</mat-icon>
              </mat-toolbar-row>
            </mat-toolbar>
            <div class="form">
              <mat-form-field fxFlex="100%" class="full-width">
                <input matInput placeholder="OTP Key"  #userOTPRef (keydown)="otpInput_onKeyDownLog($event, userOTPRef.value)" (keyup)="optKey_OnKeyUp(userOTPRef)">
              </mat-form-field>
              <div fxFlex="100%" *ngIf="errorDescription" class="error">
                <span class="error">{{errorDescription}}</span>
              </div>
              <div class="pos">
                  <button fxFlex="40%" class="primary-primary" mat-raised-button style="margin-right: 10px;" (click)="back(0)">Back</button>
                <button fxFlex="50%" mat-raised-button class="primary-primary" (click)="userOTPVerification_OnClick(userOTPRef.value);">Verification</button>
                <mat-spinner fxFlex="50%" *ngIf="loginProcess" diameter="40" style="float: right;"></mat-spinner>
              </div>
            </div>
          </div>
      </mat-tab>

    <!-- Login User -->
    <mat-tab>
      <div fxFlex="100%">
        <mat-toolbar color="#505050" class="toolbar">
          <mat-toolbar-row>
            <span>User Login</span>
            <span class="spacer"></span>
            <mat-icon class="icon" title="verified_user" >verified_user</mat-icon>
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="form">
          <mat-form-field fxFlex="100%" class="full-width">
            <input matInput placeholder="User Name" [formControl]="userInputFormControl" #userRef (keydown)="input_onKeyDownLog($event)">
          </mat-form-field>
          <mat-form-field fxFlex="100%" class="full-width">
            <input matInput="password" placeholder="Your Password" [formControl]="passwordInputFormControl" type="Password" #passwordRef
              (keydown)="input_onKeyDownLog($event)">
          </mat-form-field>
          <div fxFlex="100%" *ngIf="errorDescription" class="error">
            <span class="error">{{errorDescription}}</span>
          </div>
          <div class="pos">
            <mat-checkbox class="Remember" fxFlex="50%">Remember Me?</mat-checkbox>
            <button fxFlex="50%" class="primary-primary" class="forgetButton" mat-button (click)="selectedTab = 2;">Forget password ?</button>
          </div>
          <div class="pos">
            <button fxFlex="50%" mat-raised-button class="primary-primary" (click)="loginUser(userRef.value,passwordRef.value);" #loginInput>Login</button>
            <mat-spinner fxFlex="50%" *ngIf="loginProcess" diameter="40" style="float: right;"></mat-spinner>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- forget password -->
    <mat-tab>
      <div fxFlex="100%" class="forgetPassword" fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" fxLayoutAlign="center">
        <mat-toolbar color="#505050" class="toolbar">
          <mat-toolbar-row>
            <span>Forget Password</span>
            <span class="spacer"></span>
            <mat-icon class="icon">https</mat-icon>
          </mat-toolbar-row>
        </mat-toolbar>
        <div class="form">
          <mat-form-field class="email" fxFlex="100%">
            <mat-label>Email</mat-label>
            <input (keydown)="input_onKeyDownFrg($event)" [(ngModel)]="emailInput" matInput email required type="email">
            <mat-hint>required</mat-hint>
          </mat-form-field>
          <label id="emailStatus" class="emailStatus"></label>
          <div class="resetBack">
            <button fxFlex="40%" mat-raised-button class="backLogin" (click)="back(1)">Back</button>
            <button fxFlex="40%" mat-raised-button class="primary-primary reset" id="reset" (click)="forgetPas()">{{sendButtonText}}</button>
            <mat-spinner fxFlex *ngIf="emailProcess" diameter="40" style="float: right;"></mat-spinner>
          </div>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</mat-card>
