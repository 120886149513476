<h1 style="margin: 10px;">Role Access (<b>{{applicationName}} / {{roleName}}</b>)</h1>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <div class="node">
      <!-- <button *ngIf="node.type === 'AccessGroup'" mat-icon-button >
          <mat-icon class="group">groups</mat-icon>
          <mat-icon class="manage">manage_accounts</mat-icon>
      </button>           -->
      <!-- <mat-icon *ngIf="node.type === 'Access'" class="child-node" >accessibility</mat-icon>  -->
      <mat-checkbox class="checklist-leaf-node"
                  [checked]="checklistSelection.isSelected(node)"
                  (change)="todoLeafItemSelectionToggle(node)">{{node.text}}</mat-checkbox>
    </div>
   </mat-tree-node>

  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.item">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <div class="node">
      <!-- <button *ngIf="node.type === 'AccessGroup'" mat-icon-button>
        <mat-icon class="group">groups</mat-icon>
      </button>           -->
      <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="todoItemSelectionToggle(node)">{{node.text}}</mat-checkbox>
      <mat-icon class="child-node" *ngIf="node.type === 'Access'" >add</mat-icon>
    </div>
  </mat-tree-node>
</mat-tree>
<div class="parameter-action">
  <button mat-raised-button (click)="goBack_onClick()">Back</button>
  <button mat-raised-button color="primary" (click)="save_onClick()">Save</button>
</div>
