
    <mat-tab-group header-less-tabs [selectedIndex]="selectedIndex" style="width: 100%;">            
      <mat-tab label="List">
        <div class="top-row"> 
          <button mat-mini-fab color="primary" (click)="newUser_onClick()" style="margin: 15px; background-color: #1880d3;">
            <mat-icon>add</mat-icon>
          </button>
          <div class="top-action">
            <mat-form-field appearance="legacy" style="margin-right: 10px;">            
              <mat-select [(ngModel)]="accountID" placeholder="Account" (selectionChange)="getUserList()">
                <mat-option *ngFor="let account of accountList" [value]="account.AccountID">
                  {{account?.AccountName}}
                </mat-option>
              </mat-select>          
            </mat-form-field>

            <mat-form-field appearance="legacy" style="margin-right: 10px;">            
              <mat-select [(ngModel)]="applicationID" placeholder="Application" (selectionChange)="getUserList()">
                <mat-option *ngFor="let app of applicationList" [value]="app.ApplicationID">
                  {{app?.ApplicationName}}
                </mat-option>
              </mat-select>          
            </mat-form-field>  

            <mat-form-field appearance="legacy" class="search-field">
              <mat-label>Search</mat-label>
              <input matInput placeholder="Search List" [(ngModel)]="search" (keyup.enter)="getUserList()">
              <mat-icon matSuffix>search</mat-icon>  
            </mat-form-field>
          </div>
          
          
        </div>
        
        <dynamic-mat-table 
          #table 
          [columns]="fields"
          rowHeight="38"
          [dataSource]="dataSource"       
          [tableName]="gridName"
          [sticky]="true"
          [showNoData]="true"
          [pagination]="pagination"
          [pagingMode]="true"
          [printConfig]="printConfig"
          [setting] = "tableSetting"
          (settingChange)="table_onChangeSetting($event)"
          (onRowEvent)="rowTable_onEvent($event)"
          (paginationChange)="table_PaginationChange($event)">
        </dynamic-mat-table>      
      </mat-tab>
      <mat-tab label="New Edit Role">  
        <ng-template matTabContent>
          <app-create-edit-user *ngIf="currentRecord" [user]="currentRecord" (event)="page_onEvent($event)"></app-create-edit-user>
        </ng-template>        
      </mat-tab>
      <mat-tab label="Default Param">        
        <ng-template matTabContent>
          <app-edit-user-param *ngIf="currentRecord" [user]="currentRecord" (event)="page_onEvent($event)"></app-edit-user-param>
        </ng-template>
      </mat-tab>
    </mat-tab-group> 