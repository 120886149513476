import { SelectionModel } from '@angular/cdk/collections';
import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BaseComponent } from './BaseComponent';
import { TreeNewNodeDefDirective } from './directives/tree-new-node-def.directive';
import { TreeCustomNodeDefDirective } from './directives/tree-custom-node-def.directive';
import { TreeEditNodeDefDirective } from './directives/tree-edit-node-def.directive';
import { TreeLeafNodeDefDirective } from './directives/tree-leaf-node-def.directive';
import { TreeNodeDefDirective } from './directives/tree-node-def.directive';
import { FlatNode } from './models/flat-node.model';
import { TreeEvent } from './models/tree-event.model';
import { NodeDefType } from './models/tree-node.model';
import { MatTree } from '@angular/material/tree';

@Component({
  selector: 'dm-tree',
  templateUrl: './dm-tree.component.html',
  styleUrls: ['./dm-tree.component.scss']
})
export class DmTreeComponent extends BaseComponent implements OnInit, OnChanges {
  @Output() event = new EventEmitter<TreeEvent>();

  @Input() dataSource : any[];
  @Input() nodePadding: number = 20;

  @ViewChild('treeSelector', {static: true})
  set _(value: MatTree<any>)  {
    console.log(value);
    this.tree = value;
  }

  @ContentChild(TreeNodeDefDirective, { read: TemplateRef })
  nodeDef: TemplateRef<TreeNodeDefDirective>;

  @ContentChild(TreeLeafNodeDefDirective, { read: TemplateRef })
  leafNodeDef: TemplateRef<TreeLeafNodeDefDirective>;

  @ContentChild(TreeEditNodeDefDirective, { read: TemplateRef })
  editNodeDef: TemplateRef<TreeEditNodeDefDirective>;

  @ContentChild(TreeNewNodeDefDirective, { read: TemplateRef })
  newNodeDef: TemplateRef<TreeNewNodeDefDirective>;

  @ContentChild(TreeCustomNodeDefDirective, { read: TemplateRef })
  customNodeDef: TemplateRef<TreeCustomNodeDefDirective>;

  getTemplateDef(node: FlatNode) {
    if(node.type === NodeDefType.NormalTemplate) {
      return this.nodeDef;
    } else if(node.type === NodeDefType.LeafTemplate) {
      return this.leafNodeDef;
    } else if(node.type === NodeDefType.NewTemplate) {
      return this.newNodeDef;
    } else if(node.type === NodeDefType.EditTemplate) {
      return this.editNodeDef;
    } else if(node.type === NodeDefType.CustomTemplate) {
      return this.customNodeDef;
    }
  }

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataSource?.currentValue) {
      this.treeDataSource.data = this.dataSource;
    }
  }

  ngOnInit() {
  }

  nodeContext(node) {
    return {
      $implicit: node,
      checked: true
    };
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: FlatNode): void {
    this.nodeSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.event.emit({ type: 'SelectionChanged', data: this.nodeSelection.selected });
  }

}
