import { SelectOption } from "@controls/dm-select/select.option";

export class RoleDefaultParam {
    ParameterID?: number;
    ParameterName?: string; // ID In Dataset List
    ParameterLabel?: string; // Display In Dataset List
    OperandType?: OperandType | any;
    ParamOperand?: ListType | ScalarType | StringType;
    ParameterAction?: string;
    ParameterDatasetName?: string;
    ParameterDesc?: string;
    PermissionMessageLevel?: number;
    ParamRegex?: ParamRegexType;
    ParameterOperandAction?: string;
    AccessID?: number;
    DP_TYPE_FLAG?: 1 | 2;
    DP_TYPE_TITLE?: 'GLOBAL' | 'SERVICE';
    ParamFilterField?: string;
    /* System Field */
    SelectedData?: any[] | any = null;
    DataList?: any[] | any = null;
    SelectOption: SelectOption;
    ApplicationID?: number;
}

export class DefaultParamValue {
//     DefaultParamValue: string | number[] | number | any; //  "[5,6]"
//     ApplicationName: string; // "Scheduler "
//     // ParameterName: string; // "StudentID"
//     // ParameterType: ParamType; // 1
//     ParameterTypeName: string; // "List"
//     // PermissionMessageLevel: number; // 2
//     // AccessID: null
// AccessParameterID: null
// DP_TYPE: 1
// DP_TYPE_NAME: "GLOBAL"
// DefaultParamID: number; // 1
// Op: "="
// OperandAction: "IN"
// OperandID: 1
// ParameterID: 2

// ProcedureName: string;
// Regex: "_eq"
RoleID: number; // 1
RoleUserID: number; // 1032
Type: number; // 1
UserID: number; // 67
}

export enum OperandType {
    'List' = 1,
    'Scalar' = 2,
    'String' = 3
}
export declare type ListType = '=' | '<>';
export declare type ScalarType = '>=' | '<=' |'>' | '<' | '=' | '<>';
export declare type StringType = 'NOT LIKE' | 'LIKE'
export declare type ParamRegexType = '_lt'| '_gq'   | '_gt' | '_lq' | '_en' | '_eq' | '_neq';

