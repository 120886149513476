import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParamsHandler } from '@core/params-handler';
import { Application, Role } from '@models/Role';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';

@Component({
  selector: 'app-create-edit-role',
  templateUrl: './create-edit-role.component.html',
  styleUrls: ['./create-edit-role.component.scss']
})
export class CreateEditRoleComponent implements OnInit {
  @Input() role: Role = new Role();
  @Output() event = new EventEmitter<any>();
  applicationList: Application[] = [];
  constructor(private gs: GlobalService) { }

  ngOnInit() {
    this.getApplicationList();
  }

  goBack_onClick() {
    this.event.emit({ selectedIndex: 0, refresh: true});
  }

  save_onClick() {
    let methodName = 'edit';
    if(this.role.RoleID === null || this.role.RoleID === undefined) {
      delete this.role.RoleID;
      methodName = 'add';
    }

    ApiRequest('POST')
    .schemaName(null)
    .controller('role')
    .action(methodName)
    .setBody(new ParamsHandler(this.role))
    .addBody('RoleStatus', this.role.RoleStatus === true ? 1 : 0)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.gs.toaster.open({type: 'success', text: 'Add/Edit Successful.' });
        this.goBack_onClick();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  getApplicationList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('application')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.applicationList = resp.Data.Application;
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

}
