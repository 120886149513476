import { Component, Input, Output, EventEmitter, forwardRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'control-checkbox',
  templateUrl: './checkbox.component.html',
  styles: [':host{ width: min-content; height: min-content;}'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
},
 // { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'noop' }
]
})
export class CheckboxComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() inputModel?: boolean = null;
  @Output() inputModelChange = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter<boolean>();
  tape = [true, false, null];

  onChange: (val: boolean) => void;
  onTouched: () => void;
  disabled: boolean;
  indeterminate: boolean;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.setThreeState();
  }

  setThreeState() {
    if (this.inputModel === null) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
    }
  }

  selectedChanged(e) {
    this.inputModel = this.tape[(this.tape.indexOf(this.inputModel) + 1) % this.tape.length];
    this.setThreeState();
    if (this.changed) {
      this.changed.emit(this.inputModel );
    }
    if (this.onChange) {
      this.onChange(this.inputModel );
    }
  }

  writeValue(value: boolean): void {
    this.inputModel = value ? value : null;
    this.setThreeState();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
