<mat-card style="padding-bottom: 0; padding-left: 0; padding-right: 0;">
  <mat-tab-group header-less-tabs [selectedIndex]="selectedIndex" style="width: 100%;">
    <mat-tab label="User Info">
      <div class="user-edit-page">
        <div class="colum-first">
          <h1>User Information</h1>
          <mat-form-field class="input" appearance="fill">
            <mat-label>Full Name</mat-label>
            <input matInput placeholder="Full Name" [(ngModel)]="user.FormalName">
          </mat-form-field>
          <br>
          <mat-form-field class="input" appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" [(ngModel)]="user.Email" (ngModelChange)="user.UserName = user.Email;">
          </mat-form-field>
          <br>
          <mat-form-field class="input" appearance="fill">
            <mat-label>Cell Phone</mat-label>
            <input matInput placeholder="Cell Phone" [(ngModel)]="user.CellPhone">
          </mat-form-field>
          <br>
          <mat-form-field class="input" appearance="fill">
            <mat-label>User Kind</mat-label>
            <mat-select [(ngModel)]="userKindID">
              <mat-option *ngFor="let kind of userKindList" [value]="kind.UserKindID">
                {{kind.UserKindName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br>
          <mat-form-field class="input" appearance="fill">
            <mat-label>User State</mat-label>
            <mat-select [(ngModel)]="stateID">
              <mat-option *ngFor="let state of stateList" [value]="state.StateID">
                {{state.StateName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <br>

          <h1>Account Information</h1>
          <mat-form-field appearance="fill" class="input" *ngIf="user.UserID === null">
            <mat-select [(ngModel)]="user.AccountID" placeholder="Account" disabled>
              <mat-option *ngFor="let account of accountList" [value]="account.AccountID">
                {{account?.AccountName}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="input" appearance="fill">
            <mat-label>User Name</mat-label>
            <input matInput placeholder="User Name" [(ngModel)]="user.UserName" readonly>
          </mat-form-field>
          <br>
          <mat-form-field class="input" appearance="fill" *ngIf="user.UserID === null">
            <mat-label>Enter your password</mat-label>
            <input matInput [(ngModel)]="password" [type]="hide ? 'password' : 'text'" >
            <button mat-icon-button matSuffix (click)="hide = !hide">
              <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="colum-second">
          <h1>Application and Role Information</h1>
          <div>
            <mat-form-field appearance="fill" style="width: calc( 100% - 58px );">
              <mat-label>Application</mat-label>
              <mat-select [(ngModel)]="selectedAppList">
                <mat-option *ngFor="let app of appList" [value]="app">
                  {{app.ApplicationName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <button mat-mini-fab color="primary" (click)="appAdd_onClick()" style="margin-left: 15px; background-color: #1880d3;">
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div>
            <fieldset *ngFor="let app of user?.RolesList">
              <legend>{{app.ApplicationName}}:</legend>
              <div style="display: flex; align-items: center;">
                <mat-icon style="color: #1880d3;">settings_accessibility</mat-icon>
                <mat-form-field class="input1" appearance="fill">
                  <mat-label>Role</mat-label>
                  <mat-select [(ngModel)]="app.RoleID" placeholder="Role">
                    <mat-option *ngFor="let role of app?.RoleList" [value]="role.RoleID">
                      {{role.RoleName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button mat-mini-fab color="primary" (click)="appRemove_onClick(app)" style="margin-left: 15px; background-color: white; color: #e91e63;margin-top: -18px;">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div *ngFor="let param of app.ParameterConfigList">
                <!-- List -->
                <div *ngIf="param?.OperandType === 1 && param?.SelectOption ">
                  <mat-icon style="color: #1880d3;">filter_alt</mat-icon>

                  <dm-select [option]="param.SelectOption" class="input1"></dm-select>

                  <!-- <mat-form-field *ngIf="param?.OperandType === 1" class="input1" appearance="fill">
                    <mat-label>{{param.ParameterDesc}}</mat-label>
                    <mat-select [(ngModel)]="param.SelectedData" multiple>
                      <mat-option *ngFor="let item of param.DataList" [value]="item">
                        {{ item[param.ParameterLabel]}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  <!-- Scalar or String -->
                  <mat-form-field *ngIf="param?.OperandType === 2 || param?.OperandType === 3" class="field" appearance="fill">
                    <mat-label>{{param.ParameterLabel}}</mat-label>
                    <input matInput [(ngModel)]="param.SelectedData">
                  </mat-form-field>
                </div>
              </div>

            </fieldset>
          </div>
        </div>
      </div>

      <div class="parameter-action">
        <button mat-raised-button (click)="back_onClick()">Back</button>
        <button mat-raised-button (click)="saveUser_onClick()">Save</button>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
