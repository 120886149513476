import { Component, Input, TemplateRef } from '@angular/core';
import { TreeNodeDefDirective } from '../directives/tree-node-def.directive';
import { InternalTreeNode } from '../models/internal-tree-node.model';

@Component({
  selector: 'internal-tree-node',
  templateUrl: './internal-tree-node.component.html',
  styleUrls: ['./internal-tree-node.component.scss']
})
export class InternalNodeComponent {
  @Input() node: InternalTreeNode;
  @Input() template: TemplateRef<TreeNodeDefDirective>;
  isChecked = false;

  get nodeContext() {    
    return {
      $implicit: this.node,
      checked: this.isChecked
    };
  }

}
