import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DmSelectComponent } from './dm-select.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';



@NgModule({
  declarations: [DmSelectComponent],
  imports: [
    CommonModule, MatSelectModule, MatCheckboxModule, MatInputModule, FormsModule, MatIconModule, MatButtonModule, MatProgressBarModule,
    ReactiveFormsModule, MatBadgeModule, MatTooltipModule
  ],
  exports: [DmSelectComponent]
})
export class DmSelectModule {
  public static entry = DmSelectComponent;
 }
