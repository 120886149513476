import { Pipe, PipeTransform } from '@angular/core';
import { Lib } from '@core/lib';

@Pipe({
  name: 'secondTime'
})
export class SecondTimePipe implements PipeTransform {

  transform(second: number, shift: string = ''): string {
    return Lib.secondToTime(second, shift);
  }
}
