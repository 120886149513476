import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ParamsHandler } from '@core/params-handler';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public search$: Subject<string> = new Subject<string>();
  accountList = [];
  loading = true;

  constructor(private router: Router, private gs: GlobalService, public dialog: MatDialog) { }

  ngOnInit() {
    this.fetchAccountList();
  }

  ngAfterViewInit(): void {
    // this.search$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(search => {
    //   this.fetchFavorite(search);
    // });
  }

  fetchAccountList() {
    this.loading = true;
    ApiRequest('GET')
      .schemaName(null)
      .controller('account')
      .action('list')
      .call(this.gs).subscribe( (resp: any) => {
        resp = normalizeResponse(resp);
        this.accountList = resp.Data.Accounts;
        this.loading = false;
      });
  }

  edit_onClick(account, e) {
    if(e) {
      e.stopPropagation();
    }
    this.loading = true;
    Global.currentAccount = account;
    this.router.navigate(['pages/account-management']);
  }

  delete_onClick(account, e) {
    e.stopPropagation();
    Global.Confirmation(this.dialog, "You are about to delete the account, Are you sure?").afterClosed().subscribe( reslut => {
      if(reslut === 'Yes') {
        this.loading = true;
        ApiRequest('POST')
        .schemaName(null)
        .controller('account')
        .action('delete')
        .setBody(new ParamsHandler({ AccountID: account.AccountID}))
        .call(this.gs)
        .subscribe((resp) => {
          resp = normalizeResponse(resp);
          this.loading = false;
          if (resp.Success == true) {
            this.fetchAccountList();
          } else {
            this.gs.toaster.open({type: 'danger', text: resp.Message });
          }
        });
      }
    });
  }

  newAccount_onClick() {
    this.loading = true;
    ApiRequest('POST')
    .schemaName(null)
    .controller('account')
    .action('add')
    .setBody(new ParamsHandler({ AccountName: 'New Account', ExpirationDate: '2021-12-01'}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.fetchAccountList();
      } else {
        this.loading = false;
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  goToPage(item) {
   this.router.navigate([item.url]);
  }

}
