import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParamsHandler } from '@core/params-handler';
import { IPagination } from '@models/IPagination';
import { User } from '@models/User';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';
import { DynamicMatTableComponent, IRowEvent, PrintConfig, TableField, TablePagination, TableSetting, TableVirtualScrollDataSource } from 'dynamic-mat-table';
import { BehaviorSubject } from 'rxjs';
import { DcButtonComponent } from '../dc-button/dc-button.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  @ViewChild(DynamicMatTableComponent, { static: true })  table: DynamicMatTableComponent<any>;
  applicationID = null;
  accountID = null;
  accountList = [];
  applicationList = [];
  currentRecord: User = null;
  selectedIndex = 0;
  gridName = 'user_grid';
  fields: TableField<any>[] = [];
  dataSource = new BehaviorSubject([]); // new TableVirtualScrollDataSource([]);
  selectRows: SelectionModel<any> = new SelectionModel<any>(true);
  printConfig: PrintConfig = { title: 'User List' , showParameters: true };
  pagination: TablePagination = {
    pageIndex: 0,
    pageSize: 20,
    pageSizeOptions: [10, 20, 100, 1000, 10000]
  };
  tableSetting: TableSetting= {
    alternativeRowStyle: {'background-color': '#efefef'},
    rowStyle: {'border-bottom': 'solid 1px red;'}
  };
  constructor(public gs: GlobalService, public dialog: MatDialog) { }

  ngOnInit() {
    this.initGrid();
    this.getUserList();
    this.getApplicationList();
    this.getAccountList();
  }

  initGrid() {
    this.fields = [
    {
      name: 'UserID', header: 'User ID', width: 110
    },
    {
      name: 'UserName', header: 'User Name', width: 180
    },
    {
      name: 'AccountName', header: 'Account',  width: 180
    },
    {
      name: 'CellPhone', header: 'Cell Phone', width: 160
    },
    {
      name: 'FormalName', header: 'Full Name', width: 180
    },
    {
      name: 'LastActivity', header: 'Last Activity', width: 180
    },
    {
      name: 'RolesListName', header: 'Roles', width: 160
    },
    {
      name: 'State', header: 'State',
    },
    {
      name: 'Opertion',
      header: 'Opertion',
      option: [{name: 'Edit', icon: 'edit', color: 'primary'},{name: 'Delete', icon: 'delete', color: 'accent'}, {name: 'RoleManage', icon: 'settings_accessibility', color: 'warn'}],
      dynamicCellComponent: DcButtonComponent,
      width: 240,
      sticky: 'end'
    }
  ];

  }

  table_onChangeSetting(e) {

  }

  rowTable_onEvent(e: IRowEvent) {
    if(e.event === 'ButtonClick' && e.sender.name === 'Edit') {
      this.currentRecord = this.dataSource.value[e.sender.row] as User;
      this.selectedIndex = 1;
    } else if(e.event === 'ButtonClick' && e.sender.name === 'Delete') {
      Global.Confirmation(this.dialog, "You are about to delete the user, Are you sure?").afterClosed().subscribe( reslut =>{
        if(reslut === 'Yes') {
          this.deleteUser(this.dataSource.value[e.sender.row].UserID);
        }
      });
    } else if(e.event === 'ButtonClick' && e.sender.name === 'RoleManage') {
      this.currentRecord = this.dataSource.value[e.sender.row] as User;
      this.selectedIndex = 2;
    }
  }

  table_PaginationChange(e: IPagination) {
    this.pagination.pageIndex = e.pageIndex;
    this.pagination.pageSize = e.pageSize;
    this.getUserList();
  }

  newUser_onClick() {
    this.currentRecord = new User();
    this.currentRecord.UserID = null;
    this.selectedIndex = 1;
  }

  page_onEvent(e: any) {
    if(e.selectedIndex !== undefined) {
      this.selectedIndex = e.selectedIndex;
    }
    if(e.refresh === true) {
      this.getUserList();
      this.dataSource.next([...this.dataSource.value]);
    }
  }

  deleteUser(userID) {
    ApiRequest('POST')
    .schemaName(null)
    .controller('user')
    .action('delete')
    .setBody(new ParamsHandler({ UserID: userID}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.getUserList();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  search = null;
  getUserList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('user')
    .action('list')
    .addParam('PageIndex', this.pagination.pageIndex)
    .addParam('PageSize', this.pagination.pageSize)
    .addParam('UserName', this.search)
    .addParam('ApplicationID', this.applicationID)
    .addParam('AccountID', this.accountID)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        resp.Data.User.forEach(user => {
          user.RolesListName = JSON.parse(user.RolesList)?.map( u => u.RoleName)?.join('-');
        });

        this.dataSource.next([...resp.Data.User]);
        this.pagination.length = resp.Data.RowCount;
        this.table.refreshTableSetting();
        this.tableSetting = {
          alternativeRowStyle: {'background-color': '#efefef'},
          rowStyle: {'border-bottom': 'solid 1px red;'}
        };
        this.table.pending = false;
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  getAccountList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('account')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.accountList = resp.Data.Accounts;
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  getApplicationList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('application')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.applicationList = resp.Data.Application;
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

}
