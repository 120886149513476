import { Component } from '@angular/core';
import {  Router } from '@angular/router';
import { UserService } from '@services/user.service';


@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent {

  constructor( private router: Router, private userService: UserService) {
    this.userService.relogin = true;
    userService.userLogout();
    setTimeout(() => {
      userService.UserLogin = null ;
      this.router.navigate(['login']);
    }, 2000 );
  }
}
