import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { TreeNodeDefDirective } from './directives/tree-node-def.directive';
import { InternalTreeNode } from './models/internal-tree-node.model';

@Component({
  selector: 'tree-control',
  templateUrl: './tree-control.component.html',
  styleUrls: ['./tree-control.component.scss']
})
export class TreeControlComponent implements OnInit  {  
  @Input() dataSource: InternalTreeNode[];
  @ContentChild(TreeNodeDefDirective, { read: TemplateRef })
  nodeTemplate: TemplateRef<TreeNodeDefDirective>;
  
  ngOnInit(): void {
    console.log(this.nodeTemplate);
  }

}
