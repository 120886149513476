<mat-tree #treeSelector [dataSource]="treeDataSource" [treeControl]="treeControl"> 
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="nodePadding">
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled></button>
    <!-- Select Node (Leaf)-->
    <mat-checkbox 
      [checked]="nodeSelection.isSelected(node)"
      (change)="leafItemSelectionToggle(node)"></mat-checkbox>
    <!-- Template in nodeDef -->
    <a (click)="test(0, node)">test</a>
    <ng-container *ngTemplateOutlet="getTemplateDef(node); context: nodeContext(node)"></ng-container>
  </mat-tree-node> 
  <!-- This is the tree node template for expandable nodes -->  
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="nodePadding">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    <!-- Select Node (Expandable Node)-->
    <mat-checkbox [checked]="descendantsAllSelected(node)"
                  [indeterminate]="descendantsPartiallySelected(node)"
                  (change)="nodeSelectionToggle(node)"></mat-checkbox>
    <a (click)="test(1, node)">test</a>
    <ng-container *ngTemplateOutlet="getTemplateDef(node); context: nodeContext(node)"></ng-container>
  </mat-tree-node>  
</mat-tree>