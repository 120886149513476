<div class="search-box">
    <mat-accordion class="search-panel">
      <mat-expansion-panel #expansionPanel (opened)="expansion_onOpen(expansionPanel);" (closed)="expansion_onClose(expansionPanel)">
        <mat-expansion-panel-header>
          <mat-icon class="search-icon">search</mat-icon>
          <input class="search-input" #searchInput (keydown)="searchInput_onKeyDown($event)" (ngModelChange)="searchChange.next($event)"
            (blur)="searchInput_onBlur()" (focus)="searchInput_onFocus()" matInput placeholder="جستجو"
            [matAutocomplete]="auto" [(ngModel)]="searchText">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let item of autocompleteList" [value]="item[autocompleteText]">
              <span>{{item[autocompleteText]}}</span>
            </mat-option>
          </mat-autocomplete>
        </mat-expansion-panel-header>      
        <ng-content></ng-content>
        <mat-action-row>
          <button mat-button color="primary" (click)="OnClearFilter()" class="right">
            <mat-icon aria-label="Search" matTooltip="Clear Filter!">clear</mat-icon>
          </button>
  
          <button mat-button color="primary" (click)="OnSearchByFilter()" class="right">
            <mat-icon aria-label="Search " matTooltip="Search">search</mat-icon>
          </button>
  
          <button mat-button color="primary" (click)="expansionPanel.close()" class="right">
            <mat-icon aria-label="Close Search" matTooltip="Close Search">expand_less</mat-icon>
          </button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>