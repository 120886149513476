import { Pipe, PipeTransform } from '@angular/core';
import { Lib } from '@core/lib';

@Pipe({
  name: 'secondDuration'
})
export class SecondDurationPipe implements PipeTransform {

  transform(second: number, config: string = 'hhmmss'): string {
    return Lib.secondToDuration(second, config);
  }
}
