import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { InternalTag, ISearchableDataSource } from '@controls/dm-common';
import { SelectOption } from '@controls/dm-select/select.option';
import { ParamsHandler } from '@core/params-handler';
import { Operand } from '@models/operand.model';
import { Application, Role } from '@models/Role';
import { OperandType, RoleDefaultParam } from '@models/role-default-param.model';
import { User, UserState } from '@models/User';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss']
})
export class CreateEditUserComponent implements OnInit, OnChanges {
  @Input() user: User = new User();
  @Output() event = new EventEmitter<any>();
  stateList = [
    {StateID: UserState.Active, StateName:'Active'},
    {StateID: UserState.Deactive, StateName:'Deactive'},
    {StateID: UserState.Verify, StateName:'Verify'},
  ];
  stateID = null;
  userKindID = null;
  userKindList = [];
  selectedIndex = 0;
  hide = true;
  roleList: Role[] = [];
  accountList = [];
  password = null;
  operanList: Operand[] = [];

  selectedAppList: Application = null;
  appList: Application[] = [];
  // paramValueOption: SelectOption = {
  //   name: 'paramValue',
  //   label: '',
  //   multiple: true,
  //   tagName: InternalTag.Select,
  //   appearance: 'fill',
  // };

  constructor(private gs: GlobalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(typeof this.user.RolesList === 'string') {
      this.user.RolesList = JSON.parse(this.user.RolesList);
      this.fetchUserDefaultParamValue();
    }
  }

  ngOnInit() {
    this.fetchRoleList();
    this.getAccountList();
    this.fetchOperandList();
    this.fetchApplicationList();
    this.fetchUserKindList();
    if(this.user.UserID !== null) {
      this.stateID = this.stateList.find( s => s.StateName.toLocaleLowerCase() === this.user.State.toString().toLocaleLowerCase())?.StateID;
    }
  }

  initOption(param) {
    let list = param.DataList.map( item => {
      return { key: item[param.ParameterName], text: item[param.ParameterLabel], tag: item };
    });
    const paramValueOption: SelectOption = {
      name: 'paramValue',
      label: param.ParameterDesc,
      multiple: true,
      tagName: InternalTag.Select,
      appearance: 'fill',
    };
    const ds: ISearchableDataSource<any> =
    {
      displayFieldName: 'text',
      keyFieldName:'key',
      callbackSearch: (search, dataList: any[], parent: any) => {
        const filterData = dataList.filter( f =>
          f.text.includes(search) || paramValueOption?.value?.find( item => item === f));
        console.log(filterData);
        return filterData;
      },
      list: list
    };
    paramValueOption.list = ds;
    if(paramValueOption.updateModel) {
      paramValueOption.updateModel();
    }
    return paramValueOption;
  }

  fetchOperandList() {
    ApiRequest('GET')
      .schemaName(null)
      .controller('operand')
      .action('list')
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        this.operanList = resp.Data.Operand;
      });
  }

  back_onClick() {
    this.event.emit({ selectedIndex: 0, refresh: true});
  }

  fetchApplicationList() {
    ApiRequest('GET')
      .schemaName(null)
      .controller('application')
      .action('list')
      .call(this.gs).subscribe(resp => {
        resp = normalizeResponse(resp);
        resp.Data.Application.forEach(r => {
          r.Selected = false;
        });
        this.appList = resp.Data.Application;
      });
  }

  fetchRoleList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('role')
    .action('list')
    .call(this.gs).subscribe(resp => {
      resp = normalizeResponse(resp);
      this.roleList = resp.Data.Role;
      this.updateRoleApplication();
    });
  }

  fetchUserKindList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('user')
    .action('kindlist')
    .call(this.gs).subscribe(resp => {
      this.userKindList = resp.Data.UserKind;
    });
  }

  appAdd_onClick() {
    debugger
    const role = {
      ApplicationID: this.selectedAppList.ApplicationID,
      ApplicationName: this.selectedAppList.ApplicationName,
      RoleID: null,
      RoleName: null,
      RoleList: this.roleList.filter( a => a.ApplicationID === this.selectedAppList.ApplicationID),
      ParameterConfigList: []
    };

    if(this.user.RolesList.find( r => r.ApplicationID === role.ApplicationID ) === undefined) {
      this.user.RolesList.push(role);
      this.getApplicationParameterList(role);
    } else {
      this.gs.toaster.open({ text: 'Application already exists.', type: 'danger' });
    }
  }

  appRemove_onClick(role) {
    this.user.RolesList = this.user.RolesList.filter(r => r.ApplicationID !== role.ApplicationID);
  }

  updateRoleApplication() {
    if(this.user.RolesList === null || this.user.RolesList === undefined) {
      this.user.RolesList = [];
    }
    this.user.RolesList.forEach( role => {
      role['RoleList'] = this.roleList.filter( a => a.ApplicationID === role.ApplicationID);
    });
  }

  getApplicationParameterList(applicationRole: any) {
    ApiRequest('GET')
    .schemaName(null)
    .controller('access')
    .action('defaultparamconfiglist')
    .addParam('ApplicationID', applicationRole.ApplicationID)
    .addParam('Required', 1)
    .addParam('DP_TYPE_FLAG', 1)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        applicationRole.ParameterConfigList = resp.Data.Config;
        applicationRole.ParameterConfigList?.forEach( param => {
          // param.ApplicationID = applicationRole.ApplicationID;
          param.RoleID = applicationRole.RoleID;
          this.fetchDynamicGet(param);
        });
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  fetchDynamicGet(defaultParam: RoleDefaultParam, search = null) {
    if(defaultParam.OperandType === OperandType.List) {
      defaultParam.DataList = [];
      defaultParam.SelectOption = this.initOption(defaultParam);
      const actionController = defaultParam.ParameterAction.toLowerCase().split('/');
      ApiRequest('GET')
      .schemaName(null)
      .controller(actionController[0])
      .action(actionController[1])
      .addParam(defaultParam.ParameterName, search)
      .addParam('PageIndex', 0)
      .addParam('PageSize', 1000)
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        defaultParam.DataList = [];
        defaultParam.DataList = resp.Data[defaultParam.ParameterDatasetName];
        defaultParam.SelectOption.list.list = defaultParam.DataList.map( item => {
          return { key: item[defaultParam.ParameterName], text: item[defaultParam.ParameterLabel], tag: item };
        });
        /* Select Parameter to set values */
        const param = this.globalValueList.find( f => f.ApplicationID === defaultParam.ApplicationID);
        if(param) {
          defaultParam.SelectOption.value = defaultParam.SelectOption.list.list.filter( item => param.DefaultParamValue.find( v => v === item.key) );
        }
        defaultParam.SelectOption.updateModel();
      });
    }
  }

  serviceValueList = [];
  globalValueList = [];
  fetchUserDefaultParamValue() {
    ApiRequest('GET')
      .schemaName(null)
      .controller('access')
      .action('userdefaultparamlist')
      .addParam('UserID', this.user.UserID)
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        this.serviceValueList = resp.Data.ServiceBased;
        this.globalValueList = resp.Data.Global;
        this.globalValueList.forEach( g => g.DefaultParamValue = JSON.parse(g.DefaultParamValue));
        this.user.RolesList.forEach( role => {
          this.getApplicationParameterList(role);
        });
      });
  }

  getAccountList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('account')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.accountList = resp.Data.Accounts;
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  saveUser_onClick() {
    const user = {...this.user, RoleIDList: this.user.RolesList.map( r => r.RoleID) };
    delete user.RolesList;
    user.UserKindID = this.userKindID;
    let methodName = 'edit';
    if(this.user.UserID === null || this.user.UserID === undefined) {
      delete user.UserID;
      methodName = 'register';
    }
    user.AccountID = Global.currentAccount.AccountID;
    ApiRequest('POST')
    .schemaName(null)
    .controller('user')
    .action(methodName)
    .setBody(new ParamsHandler(user))
    // .addBody('RoleIDList', JSON.stringify(this.user.RolesList.map( r => r.RoleID)))
    .addBody('Password', this.password)
    .addBody('State', this.stateID)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        let totalParam = 0;
        this.user.RolesList.forEach( (r: any) => {
          if(r.ParameterConfigList !== null && r.ParameterConfigList !== undefined) {
            totalParam++;
          }
        });
        if(totalParam > 0) {
          this.saveAllParam_onClick();
        }
        this.back_onClick();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  saveAllParam_onClick() {
    let totalTask = 0;
    this.user.RolesList.forEach( (row: any) => {
      row.ParameterConfigList?.forEach( (paramConfig: any) => {
        totalTask++;
        const param = {
          UserID:  this.user.UserID,
          RoleID: row.RoleID,
          ParameterID: paramConfig.ParameterID,
          OperandID: paramConfig.DefaultOperandID,
          DefaultParamValue: paramConfig.SelectOption?.value?.map( item => item.key),
          DP_TYPE_FLAG: paramConfig.DP_TYPE_FLAG,
        };

        ApiRequest('POST')
        .schemaName(null)
        .controller('access')
        .action('defaultparamedit')
        .setBody(new ParamsHandler(param))
        .call(this.gs)
        .subscribe((resp) => {
          resp = normalizeResponse(resp);
          totalTask--;
          if (resp.Success == true) {
            if(totalTask === 0) {
             // this.back_onClick();
              this.gs.toaster.open({type: 'success', text: 'Saved Successfully'});
            }
          } else {
            this.gs.toaster.open({type: 'danger', text: resp.Message });
          }
        });

        if(totalTask === 0) {
          this.back_onClick();
        }

      });
    });
  }

}
