import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MaterialModule } from './shared/material.module';
import { YesNoPipe } from '@shared/pipes/yes-no.pipe';
import { TrueFalsePipe } from '@shared/pipes/true-false.pipe';
import { SecondTimePipe } from '@shared/pipes/second-time.pipe';
import { SecondDurationPipe } from '@shared/pipes/second-duration.pipe';
import { ItineraryStatusPipe } from '@shared/pipes/itinerary-status.pipe';
import { RequestStatusPipe } from '@shared/pipes/request-status.pipe';
import { MenuItemComponent } from '@controls/action-list/menu-item/menu-item.component';
import { SearchPanelComponent } from '@controls/search-panel/search-panel.component';
import { ToastNotificationsModule } from '@core/toast-notification';
import { CheckboxComponent } from '@controls/checkbox/checkbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActionListComponent } from '@controls/action-list/action-list.component';
import { LoginComponent } from './master-page/login/login.component';
import { LogoutComponent } from './master-page/login/logout.component';
import { DynamicMatTableModule } from 'dynamic-mat-table';
import { ConfirmationDialogComponent } from '@controls/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProfileDialogComponent } from './master-page/toolbar/profile-dialog/profile-dialog.component';
import { ToolbarComponent } from './master-page/toolbar/toolbar.component';
import { ResetPasswordComponent } from './master-page/reset-password-component/reset-password.component';
import { NavigationPanelComponent } from './master-page/navigation-panel/navigation-panel.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { ChartModule } from 'angular-highcharts';
import { FieldWrapperComponent } from '@controls/field-wrapper/field-wrapper.component';
import { HeaderLessTabsDirective } from '@controls/header-less-tabs/header-less-tabs.directive';
import { DmCommonModule } from '@controls/dm-common';
import { DmSelectModule } from '@controls/dm-select/dm-select.module';
import { RoleManagementComponent } from './pages/aaa-management/role-management/role-management.component';
import { AAAManagementComponent } from './pages/aaa-management/aaa-management.component';
import { AccessManagementComponent } from './pages/aaa-management/access-management/access-management.component';
import { UserManagementComponent } from './pages/aaa-management/user-management/user-management.component';
import {MatTreeModule} from '@angular/material/tree';
import { CreateEditRoleComponent } from './pages/aaa-management/role-management/create-edit-role/create-edit-role.component';
import { CreateEditUserComponent } from './pages/aaa-management/user-management/create-edit-user/create-edit-user.component';
import { DcButtonComponent } from './pages/aaa-management/dc-button/dc-button.component';
import { CreateEditAccessComponent } from './pages/aaa-management/access-management/create-edit-access/create-edit-access.component';
import { CreateEditAccessRoleComponent } from './pages/aaa-management/role-management/create-edit-access-role/create-edit-access-role.component';
import { AccountManagementComponent } from './pages/account-management/aacount-management.component';
import { AccountInfoComponent } from './pages/account-management/account-info/account-info.component';
import { EditUserParamComponent } from './pages/aaa-management/user-management/edit-user-param/edit-user-param.component';
import { TreeControlModule } from '@controls/tree-control/tree-control.module';
import { DmTreeModule } from '@controls/dm-tree/dm-tree.module';
import { UploadDownloadFileComponent } from './pages/upload-download-file/upload-download-file.component';
import { ResponceInterceptorService } from '@services/responce-interceptor.service';


const pipes = [ YesNoPipe, TrueFalsePipe,  SecondTimePipe, SecondDurationPipe, ItineraryStatusPipe, RequestStatusPipe];
const controls = [ MenuItemComponent, CheckboxComponent, SearchPanelComponent, ActionListComponent, FieldWrapperComponent, HeaderLessTabsDirective, DcButtonComponent ];
const modules = [ ToastNotificationsModule, DmCommonModule, DmSelectModule, DmTreeModule ];
const pages = [ AAAManagementComponent, AccessManagementComponent, UserManagementComponent,
                CreateEditRoleComponent, CreateEditUserComponent, CreateEditAccessComponent,
                CreateEditAccessRoleComponent, AccountInfoComponent, EditUserParamComponent, UploadDownloadFileComponent];

@NgModule({
  declarations: [
    AppComponent,
      DashboardComponent,
      ConfirmationDialogComponent,
      ProfileDialogComponent,
      ToolbarComponent,
      MasterPageComponent,
      LoginComponent,
      LogoutComponent,
      ResetPasswordComponent,
      NavigationPanelComponent,
      RoleManagementComponent,
      AccountManagementComponent,
      ...pipes,
      ...controls,
      ...pages
   ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DynamicMatTableModule,
    ChartModule,
    MatTreeModule,
    TreeControlModule,
    ...modules
  ],
  exports: [
    ...modules
  ],
  entryComponents: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponceInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
