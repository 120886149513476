<div class="toolbar" *ngIf="userService?.UserLogin">
  <button mat-icon-button (click)="goHome_onClick()">
    <mat-icon class="home-icon" style="color: #1481e0;">home</mat-icon>
  </button>
  <div class="current-account" *ngIf="currentAccount" (click)="account_onClick()">{{currentAccount?.AccountName}}</div>
  <div class="search">
    <input type="text" placeholder="Search Menu..." (ngModelChange)="contactSearch$.next($event)" [(ngModel)]="searchText">
  </div>
  <button mat-icon-button class="profile-button" [matMenuTriggerFor]="profile">
    <div mat-card-avatar class="profile-image"></div>
  </button>

  <mat-menu  #profile="matMenu" class="profile-menu" >
    <i class="material-icons menu-arrow">arrow_drop_up</i>

    <div class="profile-header" (click)="$event.stopPropagation()">
      <div class="user-img-span">
        <div class="avatar" style="color:white; background: #009688">{{ firstLetterName()}}</div>
      </div>
      <div class="user-info">
        <div class="profile-name">
          <span class="guest">{{ loginResult ? loginResult.UserName : 'Guest'}}</span>
        </div>
        <div class="user-name">
          <span>{{ loginResult ? loginResult.RoleName : 'Guest'}}</span>
        </div>
        <div class="user-profile">
          <button mat-flat-button color="primary" class="profile-view">Profile</button>
          <button mat-flat-button color="transparency" (click)="userLogout()" class="log-out">Log Out</button>
        </div>
        <div class="server-time">
          <span>Server Time:</span> <span class="time">{{(st.timeAsObservable | async) | date:'hh:mm:ss aaa'}}</span>
        </div>
      </div>
    </div>

    <div class="profile-footer">
      <ul class="button-list">
          <li *ngFor="let menu of actionButtonList" [matTooltip]="menu.name">
              <button mat-flat-button  (click)="menu_OnClick(menu)">
                  <img [src]="menu.icon">
                  <span>{{menu.name}}</span>
              </button>
          </li>
      </ul>
    </div>
  </mat-menu>
</div>
<div class="main-layout">
  <div class="back-img"></div>
  <app-navigation-panel class="left-panel mat-elevation-z3" [contactSearch]="contactSearch$" *ngIf="userService.UserLogin !== null"></app-navigation-panel>
  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
