
    <mat-tab-group header-less-tabs [selectedIndex]="selectedIndex" style="width: 100%;">            
      <mat-tab label="List">
        <div class="top-row">    
          <mat-form-field appearance="legacy">            
            <mat-select [(ngModel)]="applicationID" placeholder="Application" (selectionChange)="getUserList()">
              <mat-option *ngFor="let app of applicationList" [value]="app.ApplicationID">
                {{app.ApplicationName}}
              </mat-option>
            </mat-select>          
          </mat-form-field>      
            
          

          <mat-form-field appearance="legacy" class="search-field">
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search List" [(ngModel)]="search" (keyup.enter)="getUserList()">
            <mat-icon matSuffix>search</mat-icon>  
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="newUser_onClick()">New User</button>
        </div>
        
        <dynamic-mat-table 
          #table 
          [columns]="fields"
          rowHeight="38"
          [dataSource]="dataSource"       
          [tableName]="gridName"
          [sticky]="true"
          [showNoData]="true"
          [pagination]="pagination"
          [pagingMode]="true"
          [printConfig]="printConfig"
          [setting] = "tableSetting"
          (settingChange)="table_onChangeSetting($event)"
          (onRowEvent)="rowEvent_onClick($event)"
          (paginationChange)="table_PaginationChange($event)">
        </dynamic-mat-table>      
      </mat-tab>
      <mat-tab label="New Edit Role">  
        <ng-template matTabContent>
          <app-create-edit-user *ngIf="currentRecord" [user]="currentRecord" (event)="page_onEvent($event)"></app-create-edit-user>
        </ng-template>        
      </mat-tab>
      <mat-tab label="">        
      </mat-tab>
    </mat-tab-group> 