<mat-card>
  
<dm-tree [dataSource]="dataList" (event)="tree_onEvent($event)">
  <!-- Node -->
	<tree-node *treeNodeDef="let node; let isChecked = checked">		
		<button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{node: node}" class="node-icon">                        
			<mat-icon class="group">groups</mat-icon>			
		</button>
		<button mat-icon-button (click)="test(node?.data, isChecked)">{{node?.data.name}}</button>				
	</tree-node> 
  <!-- Leaf -->
	<tree-node *treeLeafNodeDef="let node; let isChecked = checked">		
		<button mat-button (click)="test(node?.data, isChecked)">{{node?.data.name}}</button>				
	</tree-node> 
  <!-- New -->
  <tree-node *treeNewNodeDef="let node; let isChecked = checked">				
    sadasd
    {{node?.data.name}}
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>{{node?.data.name}}</mat-label>
			<input matInput type="text" [(ngModel)]="node.data.name">
			<button *ngIf="node.data.name" matSuffix mat-icon-button aria-label="Clear" (click)="node.data.name=''">
			  <mat-icon>close</mat-icon>
			</button>
		  </mat-form-field>
	</tree-node>
  <!-- Edit -->
	<tree-node *treeEditNodeDef="let node; let isChecked = checked">				
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>{{node?.data.name}}</mat-label>
			<input matInput type="text" [(ngModel)]="node.data.name">
			<button *ngIf="node.data.name" matSuffix mat-icon-button aria-label="Clear" (click)="node.data.name=''">
			  <mat-icon>close</mat-icon>
			</button>
		  </mat-form-field>
	</tree-node> 
  
</dm-tree>
<mat-menu #menu="matMenu">  
	<ng-template matMenuContent let-node="node">
	  <button mat-menu-item (click)="addNode_onClick(node)">
		<mat-icon>add</mat-icon>
		<span>New Access Group</span>
	  </button>
	  <button mat-menu-item (click)="editNode_onClick(node)">
		<mat-icon>edit</mat-icon>
		<span>Edit Access Group</span>
	  </button>
	  <button mat-menu-item (click)="deleteAccessGroup(node)">
		<mat-icon>delete</mat-icon>
		<span>Delete Access</span>
	  </button>
	  <button mat-menu-item (click)="manageAccessGroup(node)">
		<mat-icon>group_add</mat-icon>
		<span>Manage Access</span>
	  </button>
	</ng-template>  
  </mat-menu> 
</mat-card>