import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ParamsHandler } from '@core/params-handler';
import { AccessGroup } from '@models/access';
import { Application, Role } from '@models/Role';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<AccessItemNode[]>([]);

  get data(): AccessItemNode[] { return this.dataChange.value; }

  constructor() {}

  initialize(dataTree) {
    const data = this.makeTree(null, dataTree , null);
    this.dataChange.next(data);
  }

  makeTree(parent: any, data: any[], accessGroupRefID: any) {
    const root = [];
    data.filter( f => f.AccessGroupRefID === accessGroupRefID).forEach( (item: any) => {
      const node =  new AccessItemNode();
      node.type = 'AccessGroup';
      node.data = item;
      node.text = item.AccessGroupName;
      node.children = [];
      if(parent === null ) {
        node.parent = null;
        root.push(node);
      } else {
        node.parent = parent;
        parent.children.push(node);
      }
      // if(node.data['resJSON'] !== null && node.data['resJSON'] !== undefined ) {
      //   const accessList = JSON.parse(node.data['resJSON']);
      //   accessList.forEach(access => {
      //     const accNode =  new AccessItemNode();
      //     accNode.type = 'Access';
      //     accNode.data = access;
      //     accNode.text = access.AccessName;
      //     node.children.push(accNode);
      //   });
      // }
      this.makeTree(node, data, item.AccessGroupID);
    });
    return root;
  }

  /** Add an item to to-do list */
  insertItem(parent: AccessItemNode, data: AccessGroup) {
    if (parent?.children) {
      const node =  new AccessItemNode();
      node.data = data;
      node.text = data.AccessGroupName;
      node.children = [];
      node.parent = parent;
      parent.children.push(node);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: AccessItemNode, name: string) {
    node.text = name;
    this.dataChange.next(this.data);
  }

  deleteItem(node: AccessItemNode) {
    const i =node.parent.children.findIndex(f => f === node);
    node.parent.children = node.parent.children.splice(i, 1);
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-create-edit-access-role',
  templateUrl: './create-edit-access-role.component.html',
  styleUrls: ['./create-edit-access-role.component.scss'],
  providers: [ChecklistDatabase]
})
export class CreateEditAccessRoleComponent implements OnInit {
  @Input() role: Role = new Role();
  @Input() applicationName = '';
  @Input() roleName = '';
  @Output() event = new EventEmitter<any>();
  loading = true;
  isLoading = false;
  selectedIndex = 0;
  currentRecord = null;
  dataChange = new BehaviorSubject<AccessItemNode[]>([]);
  get data(): AccessItemNode[] { return this.dataChange.value; }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<AccessItemFlatNode, AccessItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<AccessItemNode, AccessItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: AccessItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<AccessItemFlatNode>;

  treeFlattener: MatTreeFlattener<AccessItemNode, AccessItemFlatNode>;

  dataSource: MatTreeFlatDataSource<AccessItemNode, AccessItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<AccessItemFlatNode>(true /* multiple */);

  constructor(
    private _database: ChecklistDatabase,
    public gs: GlobalService,
    ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<AccessItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.getAccessGroupList();
  }

  getLevel = (node: AccessItemFlatNode) => node.level;

  isExpandable = (node: AccessItemFlatNode) => node.expandable;

  getChildren = (node: AccessItemNode): AccessItemNode[] => node.children;

  hasChild = (_: number, _nodeData: AccessItemFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: AccessItemFlatNode) => _nodeData.text === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: AccessItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.text === node.text
        ? existingNode
        : new AccessItemFlatNode();
    flatNode.data = node.data;
    flatNode.type = node.type;
    flatNode.text = node.text;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: AccessItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: AccessItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: AccessItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: AccessItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: AccessItemFlatNode): void {
    let parent: AccessItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: AccessItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: AccessItemFlatNode): AccessItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: AccessItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    const newAG = new AccessGroup();
    newAG.AccessGroupName = '';
    newAG.AccessGroupRefID = parentNode.data.AccessGroupID;
    this._database.insertItem(parentNode!, newAG);
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: AccessItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    nestedNode.data.AccessGroupName = itemValue;
    this.addEditAccessGroup(nestedNode);
  }

  page_onEvent(e: any) {
    if(e.selectedIndex !== undefined) {
      this.selectedIndex = e.selectedIndex;
    }
    if(e.refresh === true) {
      this.getAccessGroupList();
    }
  }

  cancleNode(node: AccessItemFlatNode) {
    const node1 = this.flatNodeMap.get(node);
    this.isLoading = false;
    const index = node1.parent.children.findIndex( f => f.data === node1.data);
    node1.parent.children = node1.parent.children.splice(index, 1);
  }

  addEditAccessGroup(nestedNode) {
    this.isLoading = true;
    const accessGroup: AccessGroup = nestedNode.data;
    let methodName = '';
    let msg = '';
    if(accessGroup.AccessGroupID == null || accessGroup.AccessGroupID === undefined) {
      methodName = 'itemadd';
      msg = 'Add Successful.';
    } else {
      methodName = 'itemedit';
      msg = 'Edit Successful.';
    }
    ApiRequest('POST')
    .schemaName(null)
    .controller('accessgroup')
    .action(methodName)
    .setParam(new ParamsHandler(accessGroup))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      this.isLoading = false;
      if (resp.Success == true) {
        this._database.updateItem(nestedNode, accessGroup.AccessGroupName);
        this.gs.toaster.open({type: 'success', text: msg });
      } else {
        //this.gs.toaster.open({type: 'danger', text: 'Add/Edit not Successful.' });
      }
    });
  }

  deleteAccessGroup(node) {
    const accessGroup = this.flatNodeMap.get(node);
    ApiRequest('POST')
    .schemaName(null)
    .controller('accessgroup')
    .action('itemdelete')
    .setParam(new ParamsHandler({AccessGroupID: accessGroup.data.AccessGroupID}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.gs.toaster.open({type: 'success', text: 'Delete Successful.' });
        const i = accessGroup.parent.children.findIndex(f => f.data.AccessGroupID === accessGroup.data.AccessGroupID);
        accessGroup.parent.children.splice(i, 1);
        this._database.dataChange.next(this._database.data);
      } else {
        //this.gs.toaster.open({type: 'danger', text: 'Add/Edit not Successful.' });
      }
    });
  }

  allAccessGroupList = [];
  search ='';
  getAccessGroupList() {
      // this._database.initialize(this.AccessGroupList);
      // return;
      this.loading = true;
    ApiRequest('GET')
    .schemaName(null)
    .controller('accessgroup')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      this.loading = false;
      if (resp.Success == true) {
        this.allAccessGroupList = resp.Data.AccessGroupList;
        this._database.initialize(this.allAccessGroupList);

        this.getRoleAccessList();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  manageAccessGroup(node) {
    const accessGroup = this.flatNodeMap.get(node);
    this.currentRecord = accessGroup;
    this.selectedIndex = 1;
  }

  search_onClick() {
    this._database.initialize(this.allAccessGroupList.filter( a => a.AccessGroupName.toLowerCase().includes(this.search.toLowerCase())));
  }

  goBack_onClick() {
    this.event.emit({ selectedIndex: 0});
  }

  selectedAccess = [];
  getRoleAccessList() {
    const roleIDList = JSON.stringify([this.role.RoleID]);
    console.log(roleIDList);

    ApiRequest('GET')
    .schemaName(null)
    .controller('roleaccess')
    .action('list')
    .addParam('RoleIDList', roleIDList)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.selectItem(resp.Data.RoleAccess);
        this.treeControl.expandAll();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  selectItem(selectedList: any[]): any {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if ((this.treeControl.dataNodes[i].data as any).resJSON) {
        const allAccessID: number[] = JSON.parse((this.treeControl.dataNodes[i].data as any).resJSON).map( item => item.AccessID );
        if(allAccessID.some( r => selectedList.map(s => s.AccessID).indexOf(r) >=0)) {
          this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
        }
        // if(selectedList.some(r => r.AccessID === this.treeControl.dataNodes[i].data['AccessID'])) {
        //   this.todoItemSelectionToggle(this.treeControl.dataNodes[i]);
        //   this.treeControl.expand(this.treeControl.dataNodes[i])
        // }
      }
    }
  }

  save_onClick() {
    const nodeIDList = [];
    this.checklistSelection.selected.forEach( (item: any) => {
      if(item.data.resJSON) {
        const selectedChild = JSON.parse(item.data.resJSON);
        nodeIDList.push(...selectedChild.map(f => f.AccessID));
      }
    });
    // const nodeIDList = this.checklistSelection.selected.filter( f => f.type === 'Access').map(f => f.data['AccessID']);
    ApiRequest('POST')
    .schemaName(null)
    .controller('roleaccess')
    .action('add')
    .setBody(new ParamsHandler({RoleID: this.role.RoleID, RoleAccessList: JSON.stringify(nodeIDList)}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.gs.toaster.open({type: 'success', text: 'Update Successful.' });
        this.goBack_onClick();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }
}

/**
 * Node for to-do item
 */
 export class AccessItemNode {
  children: AccessItemNode[];
  data: AccessGroup;
  text: string;
  parent: AccessItemNode;
  type: 'AccessGroup' | 'Access'= 'AccessGroup';
}

/** Flat to-do item node with expandable and level information */
export class AccessItemFlatNode {
  text: string;
  level: number;
  data: AccessGroup;
  type: 'AccessGroup' | 'Access'
  expandable: boolean;
}
