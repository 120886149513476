import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationData) { }

  ngOnInit() {
    console.log(this.data);

  }

  getText(btn) {
    console.log(btn);
    return btn ? btn.text : '-';
  }

  button_onClick(btn: any): void {
    this.dialogRef.close(btn);
  }
}

export interface ConfirmationData {
  message: string;
  buttonList: {name: string, text?: string, color?: string, focus?: boolean}[];
}
