export class Role {
    RoleID: number;
    RoleName: string;
    ApplicationID: number;
    RoleStatus: boolean;
    Selected?: boolean;
}

export class RoleAccess {
    RoleID: number;
    RoleName: string;
}

export class Application {
    ApplicationID: number;
    ApplicationName: string;
}