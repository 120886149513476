
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-user-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

  user = {
    AccountID: 1,
    AccountName: 'AccountName',
    CellPhone: '5454',
    Email: 'test@gmail.com',
    FormalName: 'test',
    RoleName: 'System Administrator',
    UserID: 72,
    UserName: 'test@gmail.com'
  };
  selectedTab = 0;
  operationProcess = false;
  errorDescription: string = null;

  formalName = new FormControl();
  userName = new FormControl('', [Validators.required]);
  email = new FormControl('', [Validators.required, Validators.email]);
  cellPhone = new FormControl();
  oldPassword = new FormControl('', [Validators.required]);
  newPassword = new FormControl('', [Validators.required]);
  repeatPassword = new FormControl('', [Validators.required]);

  constructor(private dialogRef: MatDialogRef<ProfileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngOnInit() {

  }

  cancelUpdate() {
    if (this.selectedTab === 0) {
      this.dialogRef.close(null);
    } else if (this.selectedTab === 1 || this.selectedTab === 2 || this.selectedTab === 3) {
      this.selectedTab = 0;
    }
  }

  editUser() {
      this.selectedTab = 1;
  }


  truePass(pass, cPass) {

    document.getElementById('truePass').classList.remove('drawn');
    document.getElementById('trueConfirm').classList.remove('drawn');

    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);
    if (regexp.test(pass)) {
      document.getElementById('truePass').classList.add('drawn');
      if (pass === cPass) {
        document.getElementById('trueConfirm').classList.add('drawn');
      }
    }

  }

  trueConfirm(pass, cPass) {
    document.getElementById('truePass').classList.remove('drawn');
    document.getElementById('trueConfirm').classList.remove('drawn');
    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);
    if (regexp.test(pass)) {
      document.getElementById('truePass').classList.add('drawn');
      if (pass === cPass) {
        document.getElementById('trueConfirm').classList.add('drawn');
      }
    }
  }

  changePassword(password: any, confirmPassword: any) {
    this.errorDescription = '';
    if (this.selectedTab === 0) {
      this.selectedTab = 2;
    } else if (this.selectedTab === 2) {
      // change password
      const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);

      if (password === '' && confirmPassword === '') {
        document.getElementById('passLabel').textContent = 'please enter your password';
        document.getElementById('passLabel').style.color = 'red';
      } else {
        if (!regexp.test(password)) {
          document.getElementById('passLabel').textContent = 'Not a valid password';
          document.getElementById('passLabel').style.color = 'red';
        } else {

          if (password === confirmPassword) {

          } else {
            document.getElementById('passLabel').textContent = 'Password confirmation doesn\'t match Password';
            document.getElementById('passLabel').style.color = 'red';
          }
        }
      }
    }
  }


}
