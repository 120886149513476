<mat-tab-group header-less-tabs [selectedIndex]="selectedIndex" style="width: 100%;">            
  <mat-tab label="List">
    <!-- <div class="top-row">
      <mat-form-field appearance="legacy" class="search-field">
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search List" [(ngModel)]="search" (keyup.enter)="search_onClick()">
        <mat-icon matSuffix>search</mat-icon>  
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="search_onClick()">Search</button>
    </div> -->
    <h1>Access Management</h1>
    <!-- Tree Start -->
    <mat-tree #treeSelector [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <div class="node">
          <button *ngIf="node.type === 'AccessGroup'" mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{node: node}" class="node-icon">                        
              <mat-icon class="group">groups</mat-icon>
              <mat-icon class="manage">manage_accounts</mat-icon>      
          </button>          
          <mat-icon *ngIf="node.type === 'Access'" class="child-node" >accessibility</mat-icon> 
          <label class="label">{{node.text}}</label>
        </div>    
      </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node; when: hasInEditMode" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <div style="position: relative;">
          <mat-form-field appearance="fill">
            <mat-label>New Access Group</mat-label>
            <input matInput #itemValue placeholder="Access Group Name" [value]="node.text" (keyup.enter)="saveNode(node, itemValue.value)">
          </mat-form-field>
          <button mat-icon-button (click)="saveNode(node, itemValue.value)">
            <mat-icon>done</mat-icon>
          </button>
          <button mat-icon-button (click)="cancleNode(node, itemValue.value)">
            <mat-icon>close</mat-icon>
          </button>
          <mat-progress-bar class="loading" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
      </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.item">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="node">
          <button *ngIf="node.type === 'AccessGroup'" mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{node: node}" class="node-icon">                        
            <mat-icon class="group">groups</mat-icon>
            <mat-icon class="manage">manage_accounts</mat-icon>      
          </button>          
          <mat-icon class="child-node" *ngIf="node.type === 'Access'" >add</mat-icon> 
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{node: node}" class="node-icon">
            <mat-icon class="group">groups</mat-icon>
            <mat-icon class="manage">manage_accounts</mat-icon>
          </button> -->
          <label class="label">{{node.text}}</label>
        </div>    
      </mat-tree-node>
    </mat-tree>
    
    <mat-menu #menu="matMenu">  
      <ng-template matMenuContent let-node="node">
        <button mat-menu-item (click)="addNewItem(node)">
          <mat-icon>add</mat-icon>
          <span>New Access Group</span>
        </button>
        <button mat-menu-item (click)="editItem(node)">
          <mat-icon>edit</mat-icon>
          <span>Edit Access Group</span>
        </button>
        <button mat-menu-item (click)="deleteAccessGroup(node)">
          <mat-icon>delete</mat-icon>
          <span>Delete Access</span>
        </button>
        <button mat-menu-item (click)="manageAccessGroup(node)">
          <mat-icon>group_add</mat-icon>
          <span>Manage Access</span>
        </button>
      </ng-template>  
    
    </mat-menu>
    <div style="position: relative;">
      <button mat-icon-button *ngIf="addRoot === false" (click)="addRoot = true;" class="add-root">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button *ngIf="addRoot === false" (click)="refresh_onClick()" class="add-root">
        <mat-icon>refresh</mat-icon>
      </button>
      <ng-container *ngIf="addRoot === true">
        <button mat-icon-button (click)="addRoot = false; ">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <mat-form-field appearance="fill">
          <mat-label>New Access Group</mat-label>
          <input matInput #itemValue placeholder="Access Group Name" (keyup.enter)="addNode(itemValue.value)">
        </mat-form-field>
        <button mat-icon-button (click)="addNode(itemValue.value)">
          <mat-icon>done</mat-icon>
        </button>
        <mat-progress-bar class="loading" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      </ng-container>
    </div> 
    <!-- Tree End -->
  </mat-tab>
  <mat-tab label="New Edit Role">   
    <ng-template matTabContent>
      <app-create-edit-access *ngIf="currentRecord" [accessGroupNode]="currentRecord" (event)="page_onEvent($event)"></app-create-edit-access>
    </ng-template>       
  </mat-tab>
  <mat-tab label="">        
  </mat-tab>
</mat-tab-group>

