<mat-tab-group style="width: calc( 100% - 30px ); margin: 15px;" (selectedIndexChange)="onSelectedIndexChange($event)" color="primary" backgroundColor="white">
  <mat-tab *ngFor="let app of user?.RolesList">
    <ng-template mat-tab-label>
      {{app.ApplicationName}}&nbsp;
      <b>({{app.RoleName}})</b>
    </ng-template>
    <div class="user-edit-page">
      <div *ngIf="app.ParameterConfigList?.length === 0" class="no-parameter">
        {{app.ApplicationName}} application does not have any default parameter <mat-icon>info</mat-icon>
      </div>
      <!-- *ngIf="globalParamList?.length > 0 || serviceParamList?.length > 0" -->
      <div *ngIf="app.ParameterConfigList?.length > 0">
        <div class="crow">
          <!-- Param Type -->
          <div class="clm3" style="width: 33%;">
            <mat-form-field appearance="fill" class="field">
              <mat-label>Param Type</mat-label>
              <mat-select [(ngModel)]="currentParamType" (selectionChange)="paramType_onChanged($event)" class="field">
                <mat-option *ngFor="let paramType of currentParamTypeList" [value]="paramType">
                  {{paramType.ParamName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="clm3" style="width: 33%;">
            <mat-form-field appearance="fill" class="field" *ngIf="currentParamType?.ParamTypeID === 2">
              <mat-label>Service</mat-label>
              <mat-select [(ngModel)]="currentAccess" (selectionChange)="service_onChanged($event)" class="field">
                <mat-option *ngFor="let access of accessList" [value]="access">
                  {{service?.AccessName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="crow">
          <!-- Param List -->
          <div class="clm3" style="width: 33%;">
            <mat-form-field appearance="fill" class="field">
              <mat-label>Params</mat-label>
              <mat-select [(ngModel)]="currentParam" (selectionChange)="param_onChanged($event)">
                <mat-option *ngFor="let params of getParametersByType(app)" [value]="params">
                  {{params.ParameterDesc}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Operand List -->
          <div class="clm3" style="width: 33%;">
            <mat-form-field class="field" appearance="fill">
              <mat-label>Operand</mat-label>
              <mat-select [(ngModel)]="selectedOperand">
                <mat-option *ngFor="let op of filteredOperandList" [value]="op.OperandID">
                  {{ op.ParamOperand }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- Value -->
          <div class="clm3" style="width: 33%;">
            <!-- List -->
            <dm-select *ngIf="currentParam?.OperandType === 1" [option]="currentParam.SelectOption" class="input1"></dm-select>
            <!-- <mat-form-field *ngIf="currentParam?.OperandType === 1" class="field" appearance="fill">
              <mat-label >{{currentParam.ParameterLabel}}</mat-label>
              <mat-select [(ngModel)]="selectedValue" multiple>
                <mat-option *ngFor="let item of dataList" [value]="item">
                  {{ item[currentParam.ParameterLabel]}}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- Scalar or String -->
            <mat-form-field *ngIf="currentParam?.OperandType === 2 || currentParam?.OperandType === 3" class="field" appearance="fill">
              <mat-label>{{currentParam.ParameterLabel}}</mat-label>
              <input matInput [(ngModel)]="selectedValue">
            </mat-form-field>
          </div>
        </div>
        <button mat-raised-button (click)="addParam_onClick(app)">Add Param</button>
        <hr>
        <dynamic-mat-table
            class="table"
            [pending]="pending"
            [columns]="fields"
            rowHeight="60"
            [dataSource]="dataSource"
            [tableName]="gridName"
            [sticky]="true"
            [showNoData]="true"
            [pagination]="pagination"
            pagingMode="none"
            [setting] = "tableSetting"
            [rowContextMenuItems]="contextMenuItems"
            (onRowEvent)="rowTable_onEvent($event)">
        </dynamic-mat-table>
      </div>
    </div>
    <div class="parameter-action">
      <button mat-raised-button (click)="back_onClick()">Back</button>
      <button mat-raised-button (click)="saveAllParam_onClick()">Save</button>
    </div>
  </mat-tab>
</mat-tab-group>

