<mat-card class="detail-page">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div>
        <div *ngFor="let account of accountList" class="account" (click)="edit_onClick(account)">
            {{account.AccountName}}
            <button mat-icon-button (click)="delete_onClick(account, $event)" style="right: 32px;">
                <mat-icon style="color: #ff4081;">delete</mat-icon>
            </button>
            <button mat-icon-button (click)="edit_onClick(account, $event)" style="right: 80px;">
                <mat-icon style="color: #1880d3;">edit</mat-icon>
            </button>
        </div>
    </div>
    <button mat-mini-fab color="accent" class="add-account" (click)="newAccount_onClick()">
        <mat-icon>add</mat-icon>
    </button>
</mat-card>
