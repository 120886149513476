<div mat-dialog-content>
  <b>
  {{data?.message}}
</b>
<!-- <img src="../../../assets/images/question-mark.svg" style="width: 54px;float: right;"> -->
<mat-icon class="icon">contact_support</mat-icon>
 </div>
 <div mat-dialog-actions>
   <ng-container *ngFor="let btn of data.buttonList">
    <button mat-raised-button [color]="btn ? btn.color : undefined" (click)="button_onClick(btn.name)">{{btn.text}}</button>
   </ng-container>
 </div>
