<mat-form-field *ngIf="formControl"
      class="full-width"
      [ngStyle]="option?.style"
      [ngClass]="option?.class"
      [floatLabel]="option?.floatLabel"
      [appearance]="option?.appearance"
      [color]="option?.color"
      [matBadge]="option?.badge"
      [matBadgeOverlap]="option?.badgeOverlap"
      [matBadgePosition]="option?.badgePosition || 'above after'"
      [matBadgeColor]="option?.badgeColor"
      [matBadgeHidden]="option?.badgeHidden"
      [matTooltip]="option?.tooltipText"
      [matTooltipClass]="option?.tooltipClass"
      [matTooltipDisabled]="option?.tooltipDisabled"
      [matTooltipHideDelay]="option?.tooltiphideDelay"
      [matTooltipPosition]="option?.tooltipPosition || 'below'"
      [matTooltipShowDelay]="option?.tooltipShowDelay">
        <mat-label>{{option.label}}</mat-label>
        <mat-select [formControl]="formControl"
                    [attr.type]="option?.inputType"
                    [placeholder]="option?.placeholder"
                    [multiple]="option?.multiple"
                    (selectionChange)="onSelectionChange($event)"
                    (openedChange)="onOpen($event, search)">
                <!-- Search Inpu -->
           <mat-form-field class="search-input">
                <mat-icon matPrefix style="margin-left: 10px;">search</mat-icon>
                <input matInput type="text" [formControl]="searchControl" autocomplete="off" #search>
                <button mat-button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="searchControl.setValue('')">
                    <mat-icon style="font-size: 20px !important;">close</mat-icon>
                </button>
           </mat-form-field>
          <mat-progress-bar class="progress" mode="indeterminate" *ngIf="option?.loading"></mat-progress-bar>
          <mat-option *ngIf="option?.emptyItemText">{{ option?.emptyItemText }}</mat-option>
          <mat-option *ngFor="let item of originalList" [ngStyle]="item?.style" [ngClass]="item?.class" [value]="item" (keyup)="onKeyUp($event)" >
            {{item[option.list?.displayFieldName]}}
          </mat-option>
          <div class="action-button" *ngIf="option.multiple === true">
            <!-- Select / Unselect All -->
            <button mat-button (click)="selectAll_onClick()">
                <mat-icon [style.color]="option.actionColor || '#3f51b5'">check_box</mat-icon>Select All
            </button>
            <button mat-button (click)="deselectAll_onClick()">
                <mat-icon [style.color]="option.actionColor || '#3f51b5'">clear_all</mat-icon>Clear All
            </button>
      </div>
        </mat-select>
        <mat-error *ngIf="option?.error">{{option?.error}}</mat-error>
        <mat-hint *ngIf="option?.hint">{{option?.hint}}</mat-hint>
    </mat-form-field>
