import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './master-page/login/login.component';
import { MasterPageComponent } from './master-page/master-page.component';
import { AAAManagementComponent } from './pages/aaa-management/aaa-management.component';
import { AccountManagementComponent } from './pages/account-management/aacount-management.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UploadDownloadFileComponent } from './pages/upload-download-file/upload-download-file.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: 'dashboard',
    children: [
      {
        path: '',
        component: DashboardComponent,
      },
      // {
      //   path: 'otp-pages',
      //   component: MasterPageComponent,
      //   children: [
      //     {
      //       path: '',
      //       component: SchoolOtpComponent,
      //     },
      //     {
      //       path: 'save-report',
      //       component: SaveReportOtpComponent,
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'pages',
    children: [
      {
        path: 'aaa',
        component: AAAManagementComponent,
      },
      {
        path: 'account-management',
        component: AccountManagementComponent,
      },
      {
        path: 'upload-download',
        component: UploadDownloadFileComponent,
      }
    ]
    },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LoginComponent,
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
