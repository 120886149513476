import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParamsHandler } from '@core/params-handler';
import { Role } from '@models/Role';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';
import {
  PrintConfig,
  TableField,
  TablePagination,
  TableSetting,
  TableVirtualScrollDataSource,
} from 'dynamic-mat-table';
import { BehaviorSubject } from 'rxjs';
import { DcButtonComponent } from '../dc-button/dc-button.component';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.scss'],
})
export class RoleManagementComponent implements OnInit {
  currentRecord = null;
  selectedIndex = 0;
  applicationName = '';
  roleName = '';
  gridName = 'role_grid';
  fields: TableField<any>[] = [];
  dataSource = new BehaviorSubject([]); // new TableVirtualScrollDataSource([]);
  selectRows: SelectionModel<any> = new SelectionModel<any>(true);
  printConfig: PrintConfig = { title: 'Role List', showParameters: true };
  pagination: TablePagination = {
    pageIndex: 0,
    pageSize: 20,
    pageSizeOptions: [10, 20, 100, 1000, 10000]
  };
  tableSetting: TableSetting = {
    alternativeRowStyle: { 'background-color': '#efefef' },
    rowStyle: { 'border-bottom': 'solid 1px red;' },
  };
  constructor(public gs: GlobalService, public dialog: MatDialog) {}

  ngOnInit() {
    this.initGrid();
    this.getRoleList();
  }

  initGrid() {
    this.fields = [
      {
        name: 'RoleID',
        header: 'Role ID',
        width: 200,
        display: 'hiden'
      },
      {
        name: 'ApplicationID',
        header: 'Application ID',
        width: 200,
        display: 'hiden'
      },
      {
        name: 'ApplicationName',
        header: 'Application',
        width: 200,
      },
      {
        name: 'RoleName',
        header: 'Role',
        width: 200,
      },
      {
        name: 'RoleStatusName',
        header: 'Status',
        width: 200,
      },
      {
        name: 'Opertion',
        header: 'Opertion',
        option: [{name: 'Edit', icon: 'edit', color: 'primary'},{name: 'Access', icon: 'accessibility', color: 'accent'},{name: 'Delete', icon: 'delete', color: 'accent'}],
        dynamicCellComponent: DcButtonComponent,
        width: 240,
        sticky: 'end'
      },
    ];
  }

  newRole_onClick() {
    this.selectedIndex = 1;
    this.currentRecord = new Role();
  }

  table_onChangeSetting(e) {}

  rowEvent_onClick(e: any) {
    if(e.event === 'ButtonClick' && e.sender.name === 'Edit') {
      this.currentRecord = this.dataSource.value[e.sender.row];
      this.selectedIndex = 1;
    } else if(e.event === 'ButtonClick' && e.sender.name === 'Delete') {
      Global.Confirmation(this.dialog, "You are about to delete the role, Are you sure?").afterClosed().subscribe( reslut =>{
        if(reslut === 'Yes') {
          this.deleteRole(e.sender.parent.row.RoleID);
        }
      });
    } else if(e.event === 'ButtonClick' && e.sender.name === 'Access') {
      this.currentRecord = this.dataSource.value[e.sender.row];
      this.selectedIndex = 2;
      this.applicationName = this.currentRecord.ApplicationName;
      this.roleName = this.currentRecord.RoleName;
    }
  }

  table_PaginationChange(e: any) {}

  page_onEvent(e: any) {
    if(e.selectedIndex !== undefined) {
      this.selectedIndex = e.selectedIndex;
    }
    if(e.refresh === true) {
      this.getRoleList();
    }
    this.dataSource.next([...this.dataSource.value]);
  }

  deleteRole(roleID) {
    ApiRequest('POST')
    .schemaName(null)
    .controller('role')
    .action('delete')
    .setBody(new ParamsHandler({ RoleID: roleID}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.getRoleList();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  search = '';
  getRoleList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('role')
    .action('list')
    .addParam('RoleName', this.search)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.pagination.length = resp.Data?.RowCount;
        resp.Data.Role.map( r => {
          r.RoleStatusName = (r.RoleStatus === 1 || r.RoleStatus === true) ? 'Active' : 'Deactive';
        });
        this.dataSource.next(resp.Data.Role);
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }
}
