import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ResetPass } from '../../models/User';
import { ParamsHandler } from '@core/params-handler';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  userName: string;
  checkpass = false;
  error: Observable<string>;
  errorTxt: string;
  ResetPassKey: any;
  checkLinkProgress = true;
  ResetPassProcess = false;
  ResetPassUserInputFormControl: FormControl = new FormControl('', [
    Validators.required]);
  ConfirmResetPassUserInputFormControl: FormControl = new FormControl('', [
    Validators.required]);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params.ResetPassKey) {
        this.ResetPassKey = params.ResetPassKey;
        const paramsHandler = new ParamsHandler();
        paramsHandler.addParam('ResetPassKey', params.ResetPassKey);
        this.userService.checkResetPassKey$(paramsHandler).subscribe(data => {
          this.checkLinkProgress = false;
          if (data.Success === true) {
            this.checkpass = true;
            this.userName = data.Data.UserName;
          } else if (data.Success === false) {
            this.checkpass = false;
            this.errorTxt = data.Message;
          }
        });
      }
      this.ResetPassProcess = false;
    });
  }
  NewPass(password: any, confirmPassword: any) {
    // var regexp = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);

    if (password === '' && confirmPassword === '') {
      document.getElementById('passLabel').textContent = 'please enter your password';
      document.getElementById('passLabel').style.color = 'red';
    } else {
      if (!regexp.test(password)) {
        document.getElementById('passLabel').textContent = 'Not a valid password';
        document.getElementById('passLabel').style.color = 'red';
      } else {
        if (password === confirmPassword) {

          const paramsHandler = new ParamsHandler();
          paramsHandler.addParam('ResetPassKey', this.ResetPassKey);
          paramsHandler.addParam('Username', this.userName);
          paramsHandler.addParam('NewPassword', password);
          this.userService.resetPassword(paramsHandler).subscribe( resp => {
            this.ResetPassProcess = true;
            const paramsHandlerLogin = new ParamsHandler();
            paramsHandlerLogin.addParam('Username', this.userName);
            paramsHandlerLogin.addParam('Password', password);

          //  this.userService.userLogin(paramsHandlerLogin);
            setTimeout( (() => { this.router.navigate(['login']); }).bind(this), 3000 );
          });
        } else {
          document.getElementById('passLabel').textContent = 'Password confirmation doesn\'t match Password';
          document.getElementById('passLabel').style.color = 'red';
        }
      }
    }
  }
  notifyMessage(message: string, action: string = null) {
    this.snackBar.open(message);
    this.snackBar.open(message, action, {
      panelClass: ['snack-bar-color'],
      duration: 3000
    });
  }
  input_onKeyDown(sender: KeyboardEvent, pass, confirmPss) {
    if (sender.code === 'Enter' || sender.code === 'NumpadEnter') {
      this.NewPass(pass, confirmPss);
    }
  }

  getErrorMessage() {
    return this.ResetPassUserInputFormControl.hasError('required') ? 'You must enter a value' :
      '';
  }
  getErrorMessageConfirm() {
    return this.ConfirmResetPassUserInputFormControl.hasError('required') ? 'You must enter a value' :
      '';
  }
  truePass(pass, cPass) {

    document.getElementById('truePass').classList.remove('drawn');
    document.getElementById('trueConfirm').classList.remove('drawn');

    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);
    if (regexp.test(pass)) {
      document.getElementById('truePass').classList.add('drawn');
      if (pass === cPass) {
        document.getElementById('trueConfirm').classList.add('drawn');
      }
    }

  }

  trueConfirm(pass, cPass) {
    document.getElementById('truePass').classList.remove('drawn');
    document.getElementById('trueConfirm').classList.remove('drawn');
    const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,100})/);
    if (regexp.test(pass)) {
      document.getElementById('truePass').classList.add('drawn');
      if (pass === cPass) {
        document.getElementById('trueConfirm').classList.add('drawn');
      }
    }
  }
}
