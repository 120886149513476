export class Access {
    AccessID: number;
    AccessName: string;
    AccessGroupID: number;
    AccessGroupName: string;
}

export class AccessGroup {
    AccessGroupID: number;
    AccessGroupName: string;
    AccessGroupRefID: number;
    editMode: boolean;
}