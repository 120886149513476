
type SortType = 'ascending' | 'descending';

export class Lib {

  public static dayList: string[] = ['Monday', 'Tuesday', 'Wednesday',
                                      'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public static abbreviationsDayList: string[] = ['Mon.', 'Tues.', 'Wed.',
                                  'Thurs.', 'Fri.', 'Sat.', 'Sun.'];


  public static trueFalse(value?: any) {
    if (value === true || value === 1) {
      return 'True';
    } else if (value === false || value === 0) {
      return 'False';
    } else {
      return '-';
    }
  }

  public static yesNo(value?: any) {
    if (value === true || value === 1) {
      return 'Yes';
    } else if (value === false || value === 0) {
      return 'No';
    } else {
      return '-';
    }
  }

  public static weekDayListToString(weekIDList, abstract = false) {
    let result = '';
    weekIDList.forEach((e, i) => {
      if (e === 1) {
        result += (abstract === true ? this.abbreviationsDayList[i].substring(0, 2) : this.abbreviationsDayList[i]) + ' ';
      }
    });
    return result;
  }

  public static secondToDuration(second: number, config: string = 'hhmmss') {
    if (second < 0) {
      return '-';
    } else if (second) {
      second = Math.floor(second);
      const minutes = Math.floor((second % 3600) / 60);
      const hours = Math.floor(second / 3600);
      second = second % 60;
      const m = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
      const h = hours < 10 ? '0' + hours.toString() : hours.toString();
      const s = second < 10 ? '0' + second.toString() : second.toString();
      if (config === 'hhmm') {
        return h + ':' + m;
      } else if (config === 'hh') {
        return h;
      } else {
        return h + ':' + m + ':' + s;
      }
    } else {
      return '00:00';
    }
  }

  public static secondToTimeObject(second: number) {
    const minutes = Math.floor((second % 3600) / 60);
    let hours = Math.floor(second / 3600);
    second = second % 60;
    hours = hours % 12;
    return { h: hours, m: minutes, s: second };
  }

  public static secondToTime(second: number, shift = ''): string {
    if (second) {
      const minutes = Math.floor((second % 3600) / 60);
      let hours = Math.floor(second / 3600);
      const ampm = hours >= 12 && second !== 86400 ? 'pm' : 'am';
      second = second % 60;
      if (shift !== 'noshift') {
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
      }
      const s = second < 10 ? '0' + second.toString() : second.toString();
      const m = minutes < 10 ? '0' + minutes.toString() : minutes.toString();
      const h = hours < 10 ? '0' + hours.toString() : hours.toString();
      if (shift === '') {
        return h + ':' + m + ' ' + ampm;
      } else if (shift === 'noshift') {
        return h + ':' + m;
      }
    } else {
      return '00:00 ' + (shift === '' ? 'am' : '' );
    }
  }

  public static timeToSecond(time: string) {
    if (time === null) {
      return null;
    } else {
      const a = time.split(':'); // split it at the colons
      return (+a[0]) * 60 * 60 + (+a[1]) * 60;
    }
  }

  public static isJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public static isNullOrEmpty(object: object): boolean {
    if (object === undefined || object === null || JSON.stringify(object).trim() === '{}' || JSON.stringify(object).trim() === '') {
      return true;
    } else {
      return false;
    }
  }



  public static sort(array: any[], fieldName: string, type: SortType = 'ascending') {
    if (type === 'ascending') {
      array.sort((a, b) => (a[fieldName] > b[fieldName]) ? 1 : -1);
    } else {
      array.sort((a, b) => (a[fieldName] > b[fieldName]) ? -1 : 1);
    }
  }

  public static jsonEqual(obj1: any, obj2: any): boolean {
    const s1 = JSON.stringify(obj1);
    const s2 = JSON.stringify(obj2);
    if (s1 === s2) {
      return true;
    } else {
      return false;
    }
  }

  // time format HH:MM AM|PM in 24 hour
  public static formatTime(time: string) {
    if (time === null || time.length !== 8) {
      return null;
    }
    time = time.toUpperCase();
    const h = time.substring(0, 2);
    const m = time.substring(3, 2);
    const a = time.substring(6, 2);
    if (a === 'AM') {
      return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2);
    } else {
      return (h + 12).toString() + ':' + m.toString();
    }
  }

  public static formatDate(date) {
    if (date === null || date === undefined) {
      return null;
    }
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }

    return [year, month, day].join('-');
  }

  public static getColor(index): string {
    const color = ['#f44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50',
      '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'];
    return color[index % color.length];
  }

}

