import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-wrapper',
  templateUrl: './field-wrapper.component.html',
  styleUrls: ['./field-wrapper.component.scss']
})
export class FieldWrapperComponent implements OnInit {
  @Input() placeholder: string;
  @Input() multi: boolean = false;
  @Input() titleWidth: number = 60;
  
  constructor() { }

  ngOnInit() {
  }

}
