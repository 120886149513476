import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Global } from '../../shared/Global';
import { Router } from '@angular/router';
import { menuItemAnimation } from '../toolbar/toolbar.animations';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
  animations: [
    menuItemAnimation
  ]
})
export class NavigationPanelComponent implements OnInit {
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Input() contactSearch: Subject<string> = null;
  menuList = [];
  searchText = '';
  constructor(private router: Router) { }

  ngOnInit() {    
    this.menuList = Global.otpMenuItem;
    this.contactSearch
    .pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(search => {      
      this.menuList = Global.otpMenuItem.filter(item => item.name.toLowerCase().includes(search.toLowerCase()) === true);
    });
  }  

  isCurrentRoute(menu: any): boolean {
    return menu?.selected || this.router.url.startsWith(menu?.link);
  }

  menu_onClick(menu) {    
    Global.otpMenuItem.forEach( m => m.selected = false);
    this.onClick.emit(menu);
    menu.selected = true;
    this.router.navigate([menu.link]);
  }

}
