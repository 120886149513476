<mat-tab-group header-less-tabs [selectedIndex]="selectedIndex" style="width: 100%;">
  <mat-tab label="List">
    <div class="top-row">
      <button mat-mini-fab color="accent" (click)="newRole_onClick()" style="margin: 15px; background-color: #1880d3;">
        <mat-icon>add</mat-icon>
      </button>
      <mat-form-field appearance="legacy" class="search-field" >
        <mat-label>Search</mat-label>
        <input matInput placeholder="Search List" [(ngModel)]="search" (keyup.enter)="getRoleList()">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <dynamic-mat-table
      #table
      [columns]="fields"
      rowHeight="38"
      [dataSource]="dataSource"
      [tableName]="gridName"
      [sticky]="true"
      [showNoData]="true"
      [pagination]="pagination"
      [pagingMode]="true"
      [printConfig]="printConfig"
      [setting] = "tableSetting"
      (settingChange)="table_onChangeSetting($event)"
      (onRowEvent)="rowEvent_onClick($event)"
      (paginationChange)="table_PaginationChange($event)">
    </dynamic-mat-table>
  </mat-tab>
  <mat-tab label="New Edit Role">
    <ng-template matTabContent>
      <app-create-edit-role *ngIf="currentRecord" [role]="currentRecord" (event)="page_onEvent($event)"></app-create-edit-role>
    </ng-template>
  </mat-tab>
  <mat-tab label="Assign Access">
    <ng-template matTabContent>
      <app-create-edit-access-role *ngIf="currentRecord" [role]="currentRecord" [applicationName]="applicationName" [roleName]="roleName" (event)="page_onEvent($event)"></app-create-edit-access-role>
    </ng-template>
  </mat-tab>
</mat-tab-group>
