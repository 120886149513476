import { Injectable, OnDestroy } from "@angular/core";
import { AutoUnsubscribe } from "@shared/auto-unsubscribe";
import { BehaviorSubject, Observable } from "rxjs";
import { GlobalService } from "./global.service";
import { ApiRequest } from "./request.service";

@Injectable({
    providedIn: 'root'
})
@AutoUnsubscribe()
export class ServerTimeService implements OnDestroy {
    timerSync: number = null;  
    interval: number = 60 * 5 * 1000;
    offset: number = 10 * 1000;
    time: BehaviorSubject<Date>;
    enable: boolean = false;

    constructor(private gs: GlobalService) {
        this.time = new BehaviorSubject(new Date());
        setInterval(() => { this.syncTime() }, this.interval);
        if(this.enable == true) {
            setInterval(() => { 
                this.time.next(this.getServerTime());
             }, 1000);
        }
    }

    ngOnDestroy(): void {
        
    }
    

    get timeAsObservable(): Observable<Date> {        
        return this.time.asObservable();
    }

    syncTime() {
        return
        const request = ApiRequest('GET', false).controller('account').action('timereterive').schemaName(null);
        this.gs.apiRequest<any>(request).subscribe( resp => {
            if (resp.Success === true) {                
                const serverTimeMillisGMT = Date.parse(new Date(Date.parse(resp.Data.ServerTime)).toUTCString());
                const localMillisUTC = Date.parse(new Date().toUTCString());
                this.offset = serverTimeMillisGMT -  localMillisUTC;        
            }
        }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
    }

    getServerTime() {        
        const date = new Date();
        date.setTime(date.getTime() + this.offset);
        return date;
    }
}