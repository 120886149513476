import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatTree, MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ParamsHandler } from '@core/params-handler';
import { AccessGroup } from '@models/access';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<AccessItemNode[]>([]);

  get data(): AccessItemNode[] { return this.dataChange.value; }

  constructor() {
  }

  dataList = [];
  initialize(dataTree) {
    this.dataList = dataTree;
    const data = this.makeTree(null, null);
    this.dataChange.next(data);
  }

  makeTree(parent: any, accessGroupRefID: any) {
    console.log(this.dataList.length, parent?.text);

    const root = [];
    this.dataList?.filter( f => f.AccessGroupRefID === accessGroupRefID).forEach( (item: any) => {
      const node =  new AccessItemNode();
      node.type = 'AccessGroup';
      node.data = item;
      node.text = item.AccessGroupName;
      node.children = [];
      if(parent === null ) {
        node.parent = null;
        root.push(node);
      } else {
        node.parent = parent;
        parent.children.push(node);
      }
      if(node.data['resJSON'] !== null && node.data['resJSON'] !== undefined ) {
        try{
          const accessList = JSON.parse(node.data['resJSON']);
          accessList.forEach(access => {
            const accNode =  new AccessItemNode();
            accNode.type = 'Access';
            accNode.data = access;
            accNode.text = access.AccessName;
            node.children.push(accNode);
          });
        } catch {
        }

      }
      if(item.AccessGroupID){
        this.makeTree(node, item.AccessGroupID);
      }
    });
    return root;
  }

  /** Add an item to to-do list */
  insertItem(parent: AccessItemNode, data: AccessGroup) {
    if (parent?.children) {
      const node =  new AccessItemNode();
      node.data = data;
      node.text = data.AccessGroupName;
      node.children = [];
      node.parent = parent;
      parent.children.push(node);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: AccessItemNode, name: string) {
    node.text = name;
    // node.editMode = false;
    this.dataChange.next(this.data);
  }

  editItem(node: AccessItemNode): AccessItemNode[] {
    // node.editMode = true;
    const data = [...this.data];
    this.dataChange.next(data);
    return data;
  }

  deleteItem(node: AccessItemNode) {
    const i =node.parent.children.findIndex(f => f === node);
    node.parent.children.splice(i, 1);// node.parent.children =
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-access-management',
  templateUrl: './access-management.component.html',
  styleUrls: ['./access-management.component.scss'],
  providers: [ChecklistDatabase]
})
export class AccessManagementComponent implements OnInit {
  @ViewChild('treeSelector',{ static: false}) tree: MatTree<AccessItemNode>;
  isLoading = false;
  selectedIndex = 0;
  currentRecord = null;
  dataChange = new BehaviorSubject<AccessItemNode[]>([]);
  get data(): AccessItemNode[] { return this.dataChange.value; }
  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<AccessItemFlatNode, AccessItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<AccessItemNode, AccessItemFlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: AccessItemFlatNode | null = null;

  /** The new item's name */
  newItemName = '';

  treeControl: FlatTreeControl<AccessItemFlatNode>;

  treeFlattener: MatTreeFlattener<AccessItemNode, AccessItemFlatNode>;

  dataSource: MatTreeFlatDataSource<AccessItemNode, AccessItemFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<AccessItemFlatNode>(false /* multiple */);

  constructor(
    private _database: ChecklistDatabase,
    public gs: GlobalService,
    ) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<AccessItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    _database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
  }

  ngOnInit() {
    this.getAccessGroupList();
  }

  getLevel = (node: AccessItemFlatNode) => node.level;

  isExpandable = (node: AccessItemFlatNode) => node.expandable;

  getChildren = (node: AccessItemNode): AccessItemNode[] => node.children;

  hasChild = (_: number, _nodeData: AccessItemFlatNode) => _nodeData.expandable;

  // hasNoContent = (_: number, _nodeData: AccessItemFlatNode) => _nodeData.text === '';
  hasInEditMode = (_: number, _nodeData: AccessItemFlatNode) => {
    return _nodeData.editMode === true;
  };

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: AccessItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.text === node.text
        ? existingNode
        : new AccessItemFlatNode();
    flatNode.data = node.data;
    flatNode.type = node.type;
    flatNode.text = node.text;
    flatNode.level = level;
    flatNode.editMode = node.data?.editMode;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: AccessItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: AccessItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: AccessItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: AccessItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: AccessItemFlatNode): void {
    let parent: AccessItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: AccessItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.length > 0 && descendants.every(child => {
      return this.checklistSelection.isSelected(child);
    });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: AccessItemFlatNode): AccessItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: AccessItemFlatNode) {
    // node.editMode = true;
    const parentNode = this.flatNodeMap.get(node);
    const newAG = new AccessGroup();
    newAG.AccessGroupID = -1;
    newAG.editMode = true;
    newAG.AccessGroupName = '';
    newAG.AccessGroupRefID = parentNode.data.AccessGroupID;
    this.allAccessGroupList.push(newAG);
    this.refresh();
    // this._database.insertItem(parentNode!, newAG);
    // this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: AccessItemFlatNode, itemValue: string) {
    const nestedNode = this.flatNodeMap.get(node);
    nestedNode.data.AccessGroupName = itemValue;
    this.addEditAccessGroup(nestedNode);
  }

  /** Select the category so we can insert the new item. */
  editItem(node: AccessItemFlatNode) {
    // const this.treeControl.dataNodes.find(n => n === node);
    node.editMode = true;
    let parent: AccessItemFlatNode | null = this.getParentNode(node);
    if(parent === null) {
      const f = this.allAccessGroupList.find( a => a === node.data);
      f.editMode = true;
      this.refresh();
    } else {
      this.treeControl.collapse(parent);
      this.treeControl.expand(parent);
    }
  }

  page_onEvent(e: any) {
    if(e.selectedIndex !== undefined) {
      this.selectedIndex = e.selectedIndex;
    }
    if(e.refresh === true) {
      this.getAccessGroupList();
    }
  }

  cancleNode(node: AccessItemFlatNode) {
    let item = this.allAccessGroupList.find(d => d === node.data);
    item.editMode = false;
    this.refresh();
  }

  refresh() {
    this._database.initialize(this.allAccessGroupList);
    this.treeControl.expandAll();
  }

  addEditAccessGroup(nestedNode) {
    this.isLoading = true;
    const accessGroup: AccessGroup = Object.assign({},nestedNode.data);
    if(accessGroup.AccessGroupRefID === null) {
      delete accessGroup.AccessGroupRefID
    }
    let methodName = '';
    let msg = '';
    if(accessGroup.AccessGroupID == -1 || accessGroup.AccessGroupID == null) {
      methodName = 'add';
      msg = 'Add Successful.';
    } else {
      methodName = 'edit';
      msg = 'Edit Successful.';
    }
    ApiRequest('POST')
    .schemaName(null)
    .controller('accessgroup')
    .action(methodName)
    .setBody(new ParamsHandler(accessGroup))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      this.isLoading = false;
      if (resp.Success == true) {
        let item = this.allAccessGroupList.find(d => d === nestedNode.data);
        if(item) {
          item.editMode = false;
          if(methodName === 'add') {
            nestedNode.data.AccessGroupID = resp.Data.AccessGroupID; //resp.Data[0][0].AccessGroupID;
            nestedNode.data.AccessGroupRefID = resp.Data.AccessGroupRefID;
          }
          this.refresh();
        } else {
          this.getAccessGroupList();
        }
        this.gs.toaster.open({type: 'success', text: msg });
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  deleteAccessGroup(node) {
    const accessGroup = this.flatNodeMap.get(node);
    ApiRequest('POST')
    .schemaName(null)
    .controller('accessgroup')
    .action('delete')
    .setBody(new ParamsHandler({AccessGroupID: accessGroup.data.AccessGroupID}))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.gs.toaster.open({type: 'success', text: 'Delete Successful.' });
        this.getAccessGroupList();
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  addRoot = false;
  allAccessGroupList = [];
  search ='';
  getAccessGroupList() {
    ApiRequest('GET')
    .schemaName(null)
    .controller('accessgroup')
    .action('list')
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.allAccessGroupList = resp.Data.AccessGroupList;
        this._database.initialize(this.allAccessGroupList);
        setTimeout(() =>{
          this.treeControl.expandAll();
        }, 100);
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }

  addNode(name) {
    const item = {data: {AccessGroupID: null, AccessGroupName: name }};
    this.addEditAccessGroup(item);
  }

  manageAccessGroup(node) {
    const accessGroup = this.flatNodeMap.get(node);
    this.currentRecord = accessGroup;
    this.selectedIndex = 1;
  }

  search_onClick() {
    const searchItems = this.allAccessGroupList.filter( a => a.AccessGroupName.toLowerCase().includes(this.search.toLowerCase()));
    this._database.initialize(searchItems);
    this.treeControl.expandAll();
  }

  refresh_onClick() {
    this.refresh();
  }
}

/**
 * Node for to-do item
 */
 export class AccessItemNode {
  children: AccessItemNode[];
  data: AccessGroup;
  text: string;
  parent: AccessItemNode;
  editMode: boolean;
  type: 'AccessGroup' | 'Access'= 'AccessGroup';
}

/** Flat to-do item node with expandable and level information */
export class AccessItemFlatNode {
  text: string;
  level: number;
  data: AccessGroup;
  type: 'AccessGroup' | 'Access'
  expandable: boolean;
  editMode: boolean = false;
}

