import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeControlComponent } from './tree-control.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { CdkTreeModule } from '@angular/cdk/tree';
import { TreeNodeDefDirective } from './directives/tree-node-def.directive';
import { InternalNodeComponent } from './tree-node/internal-tree-node.component';
import { TreeNodeDirective } from './directives/tree-node.directive';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule, 
    MatCheckboxModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    ReactiveFormsModule,
    CdkTreeModule
  ],
  declarations: [TreeControlComponent, InternalNodeComponent, TreeNodeDefDirective, TreeNodeDirective],
  exports: [TreeControlComponent, TreeControlComponent, InternalNodeComponent, TreeNodeDefDirective, TreeNodeDirective]
})
export class TreeControlModule { }
