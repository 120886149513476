export class AccountInformation {
    AccountBucketKey: string;
    AccountBucketName: string;
    AccountFlag: number;
    AccountID: number;
    AccountName: string;
    AccountRefID: number;
    EditTimeLimit: string;
    ExpirationDate: any;
    InitialRestorePointGUID: any;
    LanguagePack: any;
    MapConfig: any;
    MaximumTripPerDay: number;
    OperationHours: any;
    RecurringRange: number;
    RoundTripIntervalTime: any;
    TimeZoneName: any;
    TravelTimeCacheServer: any;
    TripIntervalTime: any;
    UICallCenterAppConfig: any;
    UICustomerMobileAppConfig: any;
    UICustomerWebAppConfig: any;
    UIDriverMobileAppConfig: any;
    UISchedulerAppConfig: any;
    UserCacheServer: any;
    ValidReservationDay: number;
}
