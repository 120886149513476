import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ParamsHandler } from '@core/params-handler';
import { Access, AccessGroup } from '@models/access';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';

@Component({
  selector: 'app-create-edit-access',
  templateUrl: './create-edit-access.component.html',
  styleUrls: ['./create-edit-access.component.scss'],
})
export class CreateEditAccessComponent implements OnInit, OnChanges {
  @Input() accessGroupNode: any = {};
  @Output() event = new EventEmitter<any>();
  accessList: SelectedAccess[] = [];
  parentList = [];
  loading = true;

  constructor(private gs: GlobalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.accessGroupNode.currentValue) {
      this.parentList = [];
      let parent = this.accessGroupNode;
      while (parent !== null) {
        this.parentList.push(parent);
        parent = parent.parent;
      }
      this.parentList.reverse();
    }
  }

  ngOnInit() {
    this.getAccessList();
  }

  goBack_onClick() {
    this.event.emit({ selectedIndex: 0, refresh: true });
  }

  selectAll_onClick(){
    this.accessList.forEach( item => item.selected = true);
  }

  save_onClick() {
    const accessIDList = this.accessList
      .filter((f) => f.selected === true)
      .map((a) => a.AccessID);
    // const params = new ParamsHandler({
    //   AccessGroupID: this.accessGroupNode.data.AccessGroupID,
    //   AccessIDList: accessIDList,
    // });
    ApiRequest('POST')
      .schemaName(null)
      .controller('access')
      .action('accessgroupedit')
      .addBody('AccessIDList',accessIDList)//JSON.stringify(accessIDList)
      .addBody('AccessGroupID',this.accessGroupNode.data.AccessGroupID)
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        if (resp.Success == true) {
          this.gs.toaster.open({ type: 'success', text: 'Set Successful.' });
          this.goBack_onClick();
        } else {
          this.gs.toaster.open({ type: 'danger', text: resp.Message });
        }
      });
  }

  getAccessList() {
    this.loading = true;
    ApiRequest('GET')
      .schemaName(null)
      .controller('access')
      .action('list')
      .call(this.gs)
      .subscribe((resp) => {
        resp = normalizeResponse(resp);
        this.loading = false;
        if (resp.Success == true) {
            this.accessList = resp.Data.Accesses.map((a) => {
              return { ...a, selected: false };
            });
            this.getAssignAccessGroupList(this.accessGroupNode.data.AccessGroupID);

        } else {
          this.gs.toaster.open({ type: 'danger', text: resp.Message });
        }
      });
  }

  getAssignAccessGroupList(accessGroupID: number = null) {
    ApiRequest('GET')
    .schemaName(null)
    .controller('accessgroup')
    .action('list')
    .addParam('AccessGroupRefID', accessGroupID)
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        const selected = JSON.parse(resp.Data.AccessGroupList[0].resJSON);
        selected?.forEach(access => {
          const index=this.accessList.findIndex(f => f.AccessID === access.AccessID);
          if( index >=0) {
            this.accessList[index].selected = true;
          }
        });
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message });
      }
    });
  }
}

class SelectedAccess extends Access {
  selected: boolean;
}
