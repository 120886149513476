
export class User {
    UserID: number;
    AccountID: number;
    UserName: string;
    FormalName: string;
    Email: string;
    CellPhone: string;
    AccountName: string;
    State: UserState;
    UserKindID: number;
    MapConfig: any;
    RolesList: UserRole[] = [];
}

export class UserRole {
    RoleID: number;
    RoleName: string;
    ApplicationID: number;
    ApplicationName: string;
}

export class Profile extends User {
    GUID: string;
}

export class UserLogin extends User {
    GUID: string;
    Error: string;
    Token: string;
    OTPVerify: boolean;
    OTPActive = 0 | 1;
}

export enum UserState{
    Active = 1,
    Deactive = 2,
    Verify = 3,
}


export class UserList {
    Users: User[];
    Count: number;
}

export class UserAccountList {
    Users: Account[];
    Count: number;
}

export class UserAccount {
    AccountFlag: number;
    AccountID: number;
    AccountName: string;
    AccountRefID: number;
    AccountBucketName: string;
    AccountBucketKey: string;
    AccountTravelTimeFileName: string;
    AccountTimeZone: string;
}






export class Account {
    AccountID: number;
    AccountName: string;
    AccountRefID: number;
}

export class ResetPass {
    UserName: string;
}





