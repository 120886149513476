import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RequestBuilder } from './request.service';
import { Response, Dictionary } from '@core/type/new.type';
import { Toaster } from '@core/toast-notification';
import { Router } from '@angular/router';
import { UserLogin } from '@models/User';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private login$: BehaviorSubject<UserLogin>;
  private loadingCounter$: BehaviorSubject<number>;
  private repository: Repository = { dateTime: new Date()};

  constructor(public http: HttpClient, public toaster: Toaster, public router: Router) {
    this.login$ = new BehaviorSubject<UserLogin>(null);
    this.loadingCounter$ = new BehaviorSubject<number>(0);
  }


  get loadingAsObservable() {
    return this.loadingCounter$.asObservable();
  }

  public startLoading(): void {
    window.requestAnimationFrame( () => {
      this.loadingCounter$.next(this.loadingCounter$.value + 1);
    });
  }

  public finishLoading(): void {
    window.requestAnimationFrame( () => {
      if (this.loadingCounter$.value > 0) {
          this.loadingCounter$.next(this.loadingCounter$.value - 1);
      }
    });
  }

  public apiRequest<T>(request: RequestBuilder): Observable<Response<T>> {
    return request.call(this);
  }

  get userLoginAsObservable() {
    return this.login$.asObservable();
  }

  public get token(): HttpParams {
    const user: UserLogin =  this.currentUser ? this.currentUser : null;
    if (user && user.GUID) {
      return new HttpParams().set('Token', user.GUID);
    } else {
      return null;
    }
  }

  public get currentUser(): UserLogin {
    if (localStorage.getItem('currentUser') && localStorage.getItem('currentUser').length > 0) {
      this.repository.userLogin = JSON.parse(localStorage.getItem('currentUser'));
      return this.repository.userLogin;
    } else {
      return null;
    }
  }

  public userLogin(loginUser: UserLogin): void {
    this.repository.userLogin = Object.assign({}, loginUser);
    localStorage.setItem('currentUser', JSON.stringify(loginUser));
    this.login$.next(this.repository.userLogin);
  }

  public userLogout(): void {
    this.repository.userLogin = null;
    this.login$.next(this.repository.userLogin);
    this.router.navigate(['/login']);
  }

}

export interface Repository extends Dictionary<any> {
  dateTime: Date;
}
