<mat-tab-group color="accent">
  <mat-tab label="Role Management">
    <ng-template matTabContent>
      <app-role-management></app-role-management>
    </ng-template>
  </mat-tab>
  <mat-tab label="Access Management">
    <ng-template matTabContent>
      <app-access-management></app-access-management>
    </ng-template>
  </mat-tab>
  <mat-tab label="User Management">
    <ng-template matTabContent>
      <app-user-management></app-user-management>
    </ng-template>
  </mat-tab>
</mat-tab-group>