
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserLogin } from '@models/User';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoUnsubscribe } from '@shared/auto-unsubscribe';
import { ParamsHandler } from '@core/params-handler';
import { UserService } from '@services/user.service';
import { ErrorHandeling } from '@shared/error-handeling';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { GlobalService } from '@services/global.service';
import { environment } from 'src/environments/environment';

@AutoUnsubscribe()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  errorDescription: string = null;
  userLogin$: Observable<UserLogin>;
  returnUrl = 'search';
  loginProcess = false;
  userInputFormControl: FormControl = new FormControl();
  passwordInputFormControl: FormControl = new FormControl();
  selectedTab = 1;
  initCompleted = false;
  serviceProcess = false;
  forgetUserInputFormControl = new FormControl('', [Validators.required, Validators.email]);
  checkEmailValidation = false;
  sendButtonText = 'Reset password';
  emailInput = '';
  emailProcess = false;
  withToken = false;

  ngOnDestroy(): void {
    console.log('destroy');
  }

  constructor( private router: Router, private userService: UserService, private snackBar: MatSnackBar, private gs: GlobalService, private route: ActivatedRoute,) {
  }

  ngOnInit() {
    const { token, ApplicationID } = this.route.snapshot.queryParams;
    console.log(token, ApplicationID);

    if (token && token != 'undefined') {
      this.withToken = true;
      let param = new ParamsHandler();
      param.addParam('GUID', token);
      param.addParam('ApplicationID', ApplicationID || 5);
      ApiRequest('POST', true)
        .controller('user')
        .action('login')
        .setBody(param)
        .call(this.gs)
        .subscribe(
          (resp) => {
            resp = normalizeResponse(resp);
            const currentUser = (resp as any).Data.User;
            currentUser.Token = token;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.router.navigate(['search/dashboard']);
          },
          (error) => {
            window.open(environment.HomeUrl, '_self');
          }
        );
      return;
    } else {
      this.withToken = false;
      this.userService.resetDataSet();
      localStorage.setItem('currentUser', '');
      localStorage.clear();
    }
    this.userService.resetPas.subscribe(data => {
      if (this.initCompleted === true) {
        if (data.Success === false) {
          document.getElementById('emailStatus').textContent = data.Message;
          document.getElementById('emailStatus').style.color = 'red';
          this.emailInput = '';
        } else if (data.Success === true) {
          this.sendButtonText = 'Resend password';
          document.getElementById('emailStatus').textContent = 'Done! Check your email';
          document.getElementById('emailStatus').style.color = 'green';
        }
      }
      this.emailProcess = false;
    }, error => {ErrorHandeling(error); } );

    this.userLogin$ = this.userService.LoginStatus;
    this.userLogin$.subscribe(profile => {
      if (profile === null) { return; }
      if (profile.Token == null || this.userService.relogin === true || this.initCompleted === false) {
        this.errorDescription = profile.Error;
      } else if (profile.Token !== null) {
        console.log('login');
        this.selectedTab = 1;
        this.userService.UserLogin = profile;
        localStorage.setItem('user', JSON.stringify(profile));
        this.router.navigate(['/']);
      }
      this.loginProcess = false;
    }, error => {ErrorHandeling(error); } );

    this.initCompleted = true;
  }

  userOTPVerification_OnClick(key: string) {
    this.errorDescription = '';
    this.loginProcess = true;
    const paramsHandler = new ParamsHandler();
    paramsHandler.addParam('Code', key);
    this.userService.otpValidation(paramsHandler).subscribe(resp => {
      this.loginProcess = false;
      this.errorDescription = resp.Message;
      if (resp.Success === true) {
        this.router.navigate(['/']);
        this.userService.setOTPVerify(true);
      } else {
        this.userService.setOTPVerify(false);
      }

      setTimeout(() => {this.selectedTab = 2; } , 100 );
    }, error => {ErrorHandeling(error); } );
  }

  loginUser(user: any, password: any): void {
    this.errorDescription = '';
    const paramsHandler = new ParamsHandler();
    paramsHandler.addParam('username', user);
    paramsHandler.addParam('password', password);
    paramsHandler.addParam('ApplicationID', 5);

    this.userService.userLogin(paramsHandler);
    this.loginProcess = true;
  }

  notifyMessage(message: string, action: string = null) {
    this.snackBar.open(message);
    this.snackBar.open(message, action, {
      panelClass: ['snack-bar-color'],
      duration: 3000
    });
  }

  forgetPas(): void {
    console.log('ee');
    // tslint:disable-next-line: max-line-length
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (this.emailInput === '') {
      document.getElementById('emailStatus').textContent = 'please enter your email';
      document.getElementById('emailStatus').style.color = 'red';
    } else {
      if (!regexp.test(this.emailInput)) {
        document.getElementById('emailStatus').textContent = 'Not a valid email';
        document.getElementById('emailStatus').style.color = 'red';
      } else {

        const paramsHandlerFor = new ParamsHandler();
        paramsHandlerFor.addParam('Username', this.emailInput);
        this.emailProcess = true;
        const request = ApiRequest('POST', false).controller('user').action('resetpasswordrequest').schemaName(null);
        request.setBody(paramsHandlerFor);
        this.gs.apiRequest<any>(request).subscribe( resp => {
          resp = normalizeResponse(resp);
          this.emailProcess = false;
        }, () => {
          this.emailProcess = false;
        }, () => {
          this.emailProcess = false;
          });
      }
    }
  }

  otpInput_onKeyDownLog(sender: KeyboardEvent, opt: string) {
    if (sender.code === 'Enter' || sender.code === 'NumpadEnter') {
      this.userOTPVerification_OnClick(opt);
    }
  }

  optKey_OnKeyUp(v: any) {
    if (v.value.length >= 6) {
      this.userOTPVerification_OnClick(v.value);
    }
  }

  input_onKeyDownLog(sender: KeyboardEvent) {
    if (sender.code === 'Enter' || sender.code === 'NumpadEnter') {
      this.loginUser(this.userInputFormControl.value, this.passwordInputFormControl.value);
    }
  }

  input_onKeyDownFrg(sender: KeyboardEvent) {
    console.log(sender);
    if (sender.code === 'Enter' || sender.code === 'NumpadEnter') {
      this.forgetPas();
    }
  }


  back(kind: number) {
    this.userService.userLogout();
    if (kind === 1) {
      document.getElementById('emailStatus').textContent = '';
      this.sendButtonText = 'Reset password';
      this.emailInput = '';
    }
    this.selectedTab = 1;
  }

  twoFactor_OnCancle() {
    this.selectedTab = 2;
   }

  twoFactor_OnClose() {
   this.router.navigate(['/']);
  }

}

