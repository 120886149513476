import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProfileDialogComponent } from './profile-dialog/profile-dialog.component';
import { Component, OnInit, Output, EventEmitter, HostListener, AfterViewInit, ViewChild, Input } from '@angular/core';
import { menuItemAnimation } from './toolbar.animations';
import { Observable} from 'rxjs';
import { UserLogin, UserAccount } from '@models/User';
import { Global } from '@shared/Global';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { ServerTimeService } from '@services/server-time.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: [menuItemAnimation]
})
export class ToolbarComponent implements OnInit , AfterViewInit {
  accountList$: Observable<UserAccount[]>;
  accountList: UserAccount[];
  menuItems: any[] = [];
  sidNavActive = false;
  itemLength = 0;
  loading = true;
  dateVisible = true;
  loginResult: UserLogin;

  @Output() toggleSidenav = new EventEmitter<void>();

  @ViewChild(MatMenuTrigger, {static: true}) menuTrigger: MatMenuTrigger;
  actionButtonList: any[] = [];
  allActionButtonList: any[] = [
    {
      name: 'Account Management',
      link: '/profile-action/account-management',
      role: 1,
      icon: '../../../assets/images/profile-icon/account-management.png',
      status: 'link'
    },
    {
      name: 'User Management',
      link: '/profile-action/user-management',
      role: 2,
      icon: '../../../assets/images/profile-icon/user-management.png',
      status: 'link'
    },
    {
      name: 'Role Management',
      link: '/profile-action/role-management',
      role: 1,
      icon: '../../../assets/images/profile-icon/role-management.png',
      status: 'link'
    },
  ];

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    setTimeout(() => {
    this.updateMenuSelected(); // for update selected menu when back to previous page
    }, 1);
  }

  constructor(
    public userService: UserService,
    private router: Router,
    public dialog: MatDialog,
    public st: ServerTimeService
  ) {
    // this.userService.updateCurrentDate(new Date('12/1/2019'));
    //this.userService.loadUserGridConfig();
    this.loginResult = this.userService.UserLogin;
  }

  ngOnInit() {
    this.menuItems = Global.otpMenuItem;
    this.loading = false;
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.updateMenuSelected();
      this.updateVisibleMenu();
      this.actionButtonList = this.allActionButtonList;
    }, 600);
  }

  updateVisibleMenu() {
    if (document.getElementById('menuContainer') === null) { return; }
    // calc menu item width
    let i = 0;
    let l = document.getElementById('menuContainer').childNodes.length;
    for (let n = 0; n < l; n++) {
      const item: any = document.getElementById('menuContainer').childNodes[n];
      if (item.nodeName === 'A') {
        Global.otpMenuItem[i].width = item.offsetWidth;
        Global.otpMenuItem[i++].left = item.offsetLeft;
      }
    }
    // calculate size and visibility
    const containerSize: number = document.getElementById('toolbar').offsetWidth;
    l = Global.otpMenuItem.length;
    for (i = 0; i < l; i++) {
      const item = Global.otpMenuItem[i];
      if (item.left + item.width + 100 + 150 < containerSize) {
        item.location = 0; // location at top
      } else {
        Global.otpMenuItem[i].location = 1; // location at left
      }
    }
    // Update variable
    this.menuItems = Global.otpMenuItem.filter(item => item.location === 0);
    this.sidNavActive = this.menuItems.length !== Global.otpMenuItem.length;
    this.itemLength = this.menuItems.length;
  }

  menu_OnClick(menu: any) {    
    this.loading = true;
    menu.selected = true;          
    if (menu.status === 'link') {
      this.router.navigate([menu.link]).then(() => {
        setTimeout(() => {
          this.loading = false;
          this.updateMenuSelected();
          this.updateVisibleMenu();
        }, 500);
      });
    } else if (menu.status === 'external-link') {
      window.location.href = menu.link;
    }
  }
 

  updateMenuSelected() {
    Global.otpMenuItem.forEach(item => {      
      item.selected = false;
      const urlTree = this.router.parseUrl(this.router.url);
      urlTree.queryParams = {}; 
      if (urlTree.toString() === item.link) {
        item.selected = true;        
      }
    });
  }

  isProfileSelected() {
    this.actionButtonList.forEach(menu => {
      if (this.router.url.startsWith(menu.link)) {
        return true;
      }
    });
  }

  showProfile() {
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '50%',
      data: {
        User: JSON.parse(localStorage.getItem('user'))
      }
    });
  }

  userLogout() {    
    this.userService.userLogout();
    this.router.navigate(['logout']);
    this.menuTrigger.closeMenu();
  }

  firstLetterName() {
    return this.loginResult.FormalName?.split(' ').map( item => item.toUpperCase().substring(0, 1)).join(' ') || '☺';
  }
}
