import { User } from '@models/User';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { UserAccount, UserLogin, UserList } from '@models/User';
import { Observable, BehaviorSubject } from 'rxjs';
import { Response } from '@core/type/new.type';
import { Base } from '@core/base';
import { ParamsHandler } from '@core/params-handler';
import { AutoUnsubscribe } from '@shared/auto-unsubscribe';
import { TableSetting } from 'dynamic-mat-table';
import { GlobalService } from './global.service';
import { ApiRequest, normalizeResponse } from './request.service';
import { Role } from '@models/Role';


@Injectable({
  providedIn: 'root'
})
@AutoUnsubscribe()
export class UserService extends Base {

  private profile$: BehaviorSubject<UserLogin>;
  private userList$: BehaviorSubject<UserList>;
  private roleList$: BehaviorSubject<Role[]>;
  private registerUser$: BehaviorSubject<Response<void>>;
  private resetPas$: BehaviorSubject<Response<any>>;
  private currentDate$: BehaviorSubject<Date>;
  private gridConfigList$: BehaviorSubject<UserSettings[]>;

  private dataSet: {
    loginResult: UserLogin,
    errorType: string,
    token: string,
    userList: User[],
    roleList: Role[],
    userAcountList: UserAccount[],
    gridConfigList: UserSettings[]
  };

  public relogin = false;
  resetDataSet() {
    this.dataSet = {
      loginResult: null,
      // checkresetPas: null,
      // chekRestPasKey: false,
      errorType: '',
      token: null,
      userList: null,
      roleList: [],
      userAcountList: [],
      gridConfigList: []
    };

    // this.UserLogin = null;
  }

  constructor(public gs: GlobalService) {
    super('user');
    if (this.UserLogin === null) {
      this.resetDataSet();
    }
    this.profile$ = new BehaviorSubject<UserLogin>(this.UserLogin);
    this.resetPas$ = new BehaviorSubject<any>(null);
    this.userList$ = new BehaviorSubject<UserList>(null);
    this.roleList$ = new BehaviorSubject<Role[]>(null);
    this.registerUser$ = new BehaviorSubject<Response<void>>(null);
    this.currentDate$ = new BehaviorSubject<Date>(new Date());
    this.gridConfigList$ = new BehaviorSubject<UserSettings[]>(null);
  }

  public clearSubscription() {
  }

  get GetGridConfigList() {
    return this.gridConfigList$.asObservable();
  }

  get GetCurrentDate() {
    return this.currentDate$.asObservable();
  }

  updateCurrentDate(date: Date) {
    this.currentDate$.next(date);
  }

  get LoginStatus() {
    return this.profile$.asObservable();
  }

  get resetPas() {
    return this.resetPas$.asObservable();
  }

  get UserList() {
    return this.userList$.asObservable();
  }

  get UserAccountList() {
    return this.userList$.asObservable();
  }

  get TokenParams(): HttpParams {
    const user = JSON.parse(localStorage.getItem('user'));
    if (this.dataSet.token === null) {
      this.dataSet.token = user.GUID;
    }
    const params = new HttpParams().set('Token', this.dataSet.token);
    return params;
  }

  get RoleList() {
    return this.roleList$.asObservable();
  }

  get RegisterUser$() {
    return this.registerUser$.asObservable();
  }

  get UserLogin(): UserLogin {
    if (localStorage.getItem('currentUser') == null) {
      return null;
    } else {
      return JSON.parse(localStorage.getItem('currentUser'));
    }
  }

  set UserLogin(user: UserLogin) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }

  get SelectedAccountID(): number {
    if (localStorage.getItem('SelectedAccountID') == null) {
      return null;
    } else {
      return Number(localStorage.getItem('SelectedAccountID'));
    }
  }

  set SelectedAccountID(accountID: number) {
    localStorage.setItem('SelectedAccountID', accountID.toString());
  }

  addEditGridConfig(paramsHandler: ParamsHandler) {
    const request = ApiRequest('POST', false).controller('user').action('gridconfigadd').schemaName(null);
    request.setParam(paramsHandler);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
        this.loadUserGridConfig();
        this.gs.toaster.open({ type: 'danger', caption: 'Message', text: 'Setting save.' });
      } else {
        this.gs.toaster.open({ type: 'warning', caption: 'Not Found', text: 'Setting not save.' });
      }
    }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
  }

  loadUserGridConfig() {
    const request = ApiRequest('GET', false).controller('user').action('gridconfiglist').schemaName(null);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
        this.gridConfigList$.next(resp.Data.Configs);
      }
    }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
  }

  changeAccount(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('GET', false).controller('user').action('gridconfiglist').schemaName(null);
    return this.gs.apiRequest<any>(request);
  }

  userLogout() {
    const request = ApiRequest('POST', false).controller('user').action('logout').schemaName(null);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
        this.resetDataSet();
        localStorage.clear();
        this.dataSet.loginResult = null;
        this.profile$.next(null);
      }
    }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
  }

  userLogin(paramsHandler: ParamsHandler) {
    const request = ApiRequest('POST').controller(null).action('login').setBody(paramsHandler)
    .call(this.gs).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
        if (resp.Data.User) {
          if (resp.Data.User.GUID !== null && resp.Data.User.GUID !== undefined) {
            this.dataSet.token = resp.Data.User.GUID;
            this.SelectedAccountID = resp.Data.User.AccountID;
            this.UserLogin = resp.Data.User;
            this.dataSet.loginResult = Object.assign( new UserLogin(), resp.Data.User);
            this.dataSet.loginResult.FormalName = this.dataSet.loginResult.FormalName || '-';
            this.dataSet.loginResult.Token = resp.Data.User.GUID;
            this.relogin = false;
            this.profile$.next(Object.assign({}, this.dataSet).loginResult);
          } else {
            this.resetDataSet();
            const loginResult = new UserLogin();
            loginResult.Error = 'User / Password is invalid.';
            loginResult.Token = null;
            this.profile$.next(loginResult);
          }
        } else {
          this.resetDataSet();
          const loginResult = new UserLogin();
          loginResult.Error = resp.Data.Message; // Exception on login.';
          loginResult.Token = null;
          this.profile$.next(loginResult);
        }
        if (resp.Data.User.GUID !== null && resp.Data.User.GUID !== undefined) {
            this.dataSet.token = resp.Data.User.GUID;
            this.SelectedAccountID = resp.Data.User.AccountID;
            this.UserLogin = resp.Data.User;
            this.dataSet.loginResult = Object.assign( new UserLogin(), resp.Data.User);
            this.dataSet.loginResult.FormalName = this.dataSet.loginResult.FormalName || '-';
            this.dataSet.loginResult.Token = resp.Data.User.GUID;
            this.relogin = false;
            this.profile$.next(Object.assign({}, this.dataSet).loginResult);
          } else {
            this.resetDataSet();
            const loginResult = new UserLogin();
            loginResult.Error = 'User / Password is invalid.';
            loginResult.Token = null;
            this.profile$.next(loginResult);
          }
      } else {
        this.resetDataSet();
        const loginResult = new UserLogin();
        loginResult.Error = resp.Data.Message; // Exception on login.';
        loginResult.Token = null;
        this.profile$.next(loginResult);
      }
    }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
    // const url = this.getServiceUrl() + 'login';
    // return this.gs.post<any>(url, paramsHandler).subscribe((resp: TResponse<any>) => {
    //   const data = resp.Data;
    //   if (data.User) {
    //     if (data.User.GUID !== null && data.User.GUID !== undefined) {
    //       this.dataSet.token = data.User.GUID;
    //       this.SelectedAccountID = data.User.AccountID;
    //       this.UserLogin = data.User;
    //       this.dataSet.loginResult = Object.assign( new UserLogin(), data.User);
    //       this.dataSet.loginResult.FormalName = this.dataSet.loginResult.FormalName || '-';
    //       this.dataSet.loginResult.Token = data.User.GUID;
    //       this.relogin = false;
    //       this.profile$.next(Object.assign({}, this.dataSet).loginResult);
    //     } else {
    //       this.resetDataSet();
    //       const loginResult = new UserLogin();
    //       loginResult.Error = 'User / Password is invalid.';
    //       loginResult.Token = null;
    //       this.profile$.next(loginResult);
    //     }
    //   } else {
    //     this.resetDataSet();
    //     const loginResult = new UserLogin();
    //     loginResult.Error = resp.Message; // Exception on login.';
    //     loginResult.Token = null;
    //     this.profile$.next(loginResult);
    //   }
    // },
    // error => { this.gs.errorHandle(error);
    // });
  }

  resetPassword(paramsHandler: ParamsHandler) {
    const request = ApiRequest('POST', false).controller('user').action('gridconfiglist').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
    // const url = this.getServiceUrl() + 'resetpassword';
    // return this.gs.post<any>(url, paramsHandler);
  }

  checkResetPassKey$(paramsHandler: ParamsHandler): Observable < any > {
    const request = ApiRequest('POST', false).controller('user').action('checkresetpasskey').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
    // const url = this.getServiceUrl() + 'checkresetpasskey';
    // return this.gs.post<any>(url, paramsHandler);
  }

  findUserList(paramsHandler: ParamsHandler) {
    const request = ApiRequest('GET', false).controller('user').action('list').schemaName(null);
    request.setParam(paramsHandler);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
        let userList = new UserList();
        userList = { Users: resp.Data.User, Count: resp.Data.RowCount };
        this.userList$.next(userList);
      }
    }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
    // const url = this.getServiceUrl() + 'list';
    // return this.gs.get<any>(url, paramsHandler).subscribe(data => {
    //   if (data.Success === true) {
    //     let userList = new UserList();
    //     userList = { Users: data.Data.User, Count: data.Data.RowCount };
    //     this.userList$.next(userList);
    //   }
    // },
    // error => {this.gs.errorHandle(error); }
    // );
  }

  LoadRoleList(paramsHandler: ParamsHandler) {
    const request = ApiRequest('GET', false).controller('user').action('rolelist').schemaName(null);
    request.setParam(paramsHandler);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
      if (resp.Success === true) {
          this.dataSet.roleList = resp.Data.Role;
          this.roleList$.next(Object.assign({}, this.dataSet).roleList);
      }
      }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
      // const url = this.getServiceUrl() + 'rolelist';
      // return this.gs.get<any>(url, paramsHandler)
      //   .subscribe(data => {
      //     if (data.Success === true) {
      //       this.dataSet.roleList = data.Data.Role;
      //       this.roleList$.next(Object.assign({}, this.dataSet).roleList);
      //     }
      //   },
      //   error => {this.gs.errorHandle(error); }
      //   );
  }

  RegisterUser(paramsHandler: ParamsHandler) {
    const request = ApiRequest('POST', false).controller('user').action('register').schemaName(null);
    request.setParam(paramsHandler);
    this.gs.apiRequest<any>(request).subscribe( resp => {
      resp = normalizeResponse(resp);
        if (resp.Success === true) {
          this.registerUser$.next(resp.Data as Response<void>);
        }
      }, () => {this.gs.finishLoading(); }, () => {this.gs.finishLoading(); });
  }

  EditUser(paramsHandler: ParamsHandler): Observable<Response< any >> {
      const request = ApiRequest('POST', false).controller('user').action('edit').schemaName(null);
      request.setParam(paramsHandler);
      return this.gs.apiRequest<any>(request);
  }

  DeleteUser(paramsHandler: ParamsHandler): Observable<Response<any>> {
    const request = ApiRequest('POST', false).controller('user').action('delete').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  ChangePassword(paramsHandler: ParamsHandler): Observable<Response<any>> {
    const request = ApiRequest('POST', false).controller('user').action('changepassword').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  createAccount$(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('accountadd').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  editAccount$(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('accountedit').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  accountDelete$(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('accountdelete').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  otpRequest(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('otprequest').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  otpValidation(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('otpvalidate').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }

  setOTPVerify(verify: boolean) {
    if (verify === true) {
      const login: UserLogin = Object.assign({}, this.dataSet).loginResult;
      login.OTPVerify = verify;
      this.profile$.next(login);
    } else {
      this.UserLogin = null;
      this.profile$.next(null);
    }
  }

  activityList(paramsHandler: ParamsHandler): Observable<any> {
    const request = ApiRequest('POST', false).controller('user').action('activitylist').schemaName(null);
    request.setParam(paramsHandler);
    return this.gs.apiRequest<any>(request);
  }
}

export interface UserSettings {
  GridConfigID: number;
  GridConfig: TableSetting;
  GridName: string;
}
