  <div class="header">
    <h1>General Information</h1>
    <button mat-icon-button (click)="generalInfoEdit_onClick(0)">
      <mat-icon>{{generalInfoEditMode === 'View' ? 'edit' : 'done'}}</mat-icon>
    </button>
    <button mat-icon-button (click)="generalInfoEditMode = 'View'" *ngIf="generalInfoEditMode === 'Edit'">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="item-list">
    <div class="item">
      <label>Account Name:</label>
      <label *ngIf="generalInfoEditMode === 'View'">{{accountInfo?.Name || '-'}}</label>
      <mat-form-field class="input" *ngIf="generalInfoEditMode === 'Edit'" appearance="outline">
        <input matInput [(ngModel)]="accountInfo.Name" placeholder="Account Name">
      </mat-form-field>        
    </div>
    <div class="item">
      <label>Expire Date:</label>
      <label *ngIf="generalInfoEditMode === 'View'">{{accountInfo?.Expire || '-'}}</label>
      <mat-form-field class="input" *ngIf="generalInfoEditMode === 'Edit'" appearance="outline">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="accountInfo.Expire" placeholder="Expire Date">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="item">
      <label>Time Zone:</label>
      <label *ngIf="generalInfoEditMode === 'View'">{{accountInfo?.Zone || '-'}}</label>
      <mat-form-field class="input" *ngIf="generalInfoEditMode === 'Edit'" appearance="outline">
        <mat-label>Choose a Time Zoon</mat-label>
        <mat-select>
          <mat-option *ngFor="let tz of timeZoonList" [value]="tz.TimeZoneID">
            {{food.TimeZoneName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="item">
      <label>Travel Time Cache Server URL:</label>
      <label *ngIf="generalInfoEditMode === 'View'">{{accountInfo?.Cache || '-'}}</label>
      <mat-form-field class="input" *ngIf="generalInfoEditMode === 'Edit'" appearance="outline">
        <input matInput [(ngModel)]="accountInfo.Name" placeholder="Travel Time Cache Server URL">
      </mat-form-field>
    </div>
  </div>

  <hr style="margin: 20px;">

  <div class="header">
    <h1>Logical Information</h1>
    <button mat-icon-button (click)="generalInfoEdit_onClick(2)">
      <mat-icon>{{logicalInfoEditMode === 'View' ? 'edit' : 'done'}}</mat-icon>
    </button>
    <button mat-icon-button (click)="logicalInfoEditMode = 'View'" *ngIf="logicalInfoEditMode === 'Edit'">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="item-list">
    <div class="item">
      <label>Service operation time:</label>
      <label *ngIf="logicalInfoEditMode === 'View'">{{accountInfo?.Name || '-'}}</label>
      <mat-form-field class="input" *ngIf="logicalInfoEditMode === 'Edit'" appearance="outline">
        <input matInput [(ngModel)]="accountInfo.Name" placeholder="Service Operation Time">
      </mat-form-field>        
    </div>
    <div class="item">
      <label>Reservation can be done:</label>
      <label *ngIf="logicalInfoEditMode === 'View'">{{accountInfo?.Expire || '-'}}</label>
      <mat-form-field class="input" *ngIf="logicalInfoEditMode === 'Edit'" appearance="outline">          
        <input matInput [(ngModel)]="accountInfo.Expire" placeholder="Reservation Can be Done">          
      </mat-form-field>
    </div>
    <div class="item">
      <label>Maximum trip per day:</label>
      <label *ngIf="logicalInfoEditMode === 'View'">{{accountInfo?.Zone || '-'}}</label>
      <mat-form-field class="input" *ngIf="logicalInfoEditMode === 'Edit'" appearance="outline">            
        <input matInput [(ngModel)]="accountInfo.Expire" placeholder="Maximum trip per day">
      </mat-form-field>
    </div>
    <div class="item">
      <label>Reservation can be edit:</label>
      <label *ngIf="logicalInfoEditMode === 'View'">{{accountInfo?.Cache || '-'}}</label>
      <mat-form-field class="input" *ngIf="logicalInfoEditMode === 'Edit'" appearance="outline">
        <input matInput [(ngModel)]="accountInfo.Name" placeholder="Reservation can be edit">
      </mat-form-field>
    </div>
    <div class="item">
      <label>Reservation can be done for recurring:</label>
      <label *ngIf="logicalInfoEditMode === 'View'">{{accountInfo?.Cache || '-'}}</label>
      <mat-form-field class="input" *ngIf="logicalInfoEditMode === 'Edit'" appearance="outline">
        <input matInput [(ngModel)]="accountInfo.Name" placeholder="Reservation can be done for recurring">
      </mat-form-field>
    </div>
  </div>

  <hr style="margin: 20px;">

  <div class="header">
    <h1>File</h1>       
  </div>
  <div class="item">

  </div>