import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss']
})
export class SearchPanelComponent implements OnInit {
  @ViewChild('searchInput', {static: false}) searchInput: ElementRef;
  @ViewChild('expansionPanel', {static: false}) expansionPanel: MatExpansionPanel;
  @Output() filterApply = new EventEmitter<string>();
  @Output() searchChange = new EventEmitter<string>();
  @Output() resetSearch = new EventEmitter<void>();
  @Input() searchText: string;
  @Input() autocompleteList: any[] = [];
  @Input() autocompleteText: string;
  private expandable = true;
  private state = false;

  constructor() { }

  ngOnInit() {
  }

  searchInput_onKeyDown(sender: KeyboardEvent) {
    if (sender.code === 'Space') {
      const cursorPosition = this.searchInput.nativeElement.selectionStart + 1;
      const val: string = this.searchInput.nativeElement.value;
      this.searchInput.nativeElement.value = val.slice(0, this.searchInput.nativeElement.selectionStart)
        + sender.key + val.slice(this.searchInput.nativeElement.selectionEnd);
      this.searchInput.nativeElement.selectionStart = cursorPosition;
      this.searchInput.nativeElement.selectionEnd = cursorPosition;
    } else if (sender.code === 'Enter' || sender.code === 'NumpadEnter') {
      this.OnSearchByFilter();
    }
  }

  searchInput_onDataChange(text: string) {
    this.searchChange.next(text);
  }

  searchInput_onFocus() {
    this.expandable = false;
    this.state = this.expansionPanel.expanded;
  }

  searchInput_onBlur() {
    this.expandable = true;
  }

  expansion_onOpen(sender: any) {
    if (this.expandable === false && sender.expanded !== false && this.state === false) {
      sender.close();
    }
  }

  expansion_onClose(sender: any) {
    if (this.expandable === false && sender.expanded === false && this.state === true) {
      sender.open();
    }
  }

  OnClearFilter() {
    this.resetSearch.emit();
  }

  OnSearchByFilter() {
    this.filterApply.emit(this.searchText);
    this.expansionPanel.close();
  }

}
