import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root',
})
export class ResponceInterceptorService {
  constructor(private gs: GlobalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.gs?.currentUser?.GUID) {
      request = request.clone({
          setHeaders: {
              Authorization: `${this.gs.currentUser.GUID}`
          }
      });
    }

    return next
      .handle(request).pipe(
        map( req => {
          if( req instanceof HttpResponse ) {
            //return resp;
            let response = req;
            console.log(response);
            if( req.body.data !== undefined && req.body.success !== undefined && req.body.messages !== undefined) {
              response = req.clone({ body: {Data: req.body.data, Success: req.body.success, Message: req.body.messages.join(',')}})
            }
            return response;
          }
          return req;
        })
      );
  }
}
