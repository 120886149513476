import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef, ViewChild, Inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from '@services/user.service';
import { Subject } from 'rxjs';
import { UserLogin } from '@models/User';
import { MatMenuTrigger } from '@angular/material/menu';
import { ServerTimeService } from '@services/server-time.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Globals } from 'highcharts';
import { Global } from '@shared/Global';
import { GlobalService } from '@services/global.service';
import { ParamsHandler } from '@core/params-handler';
import { ApiRequest, normalizeResponse } from '@services/request.service';

@Component({
  selector: 'app-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent implements OnInit {
  actionButtonList = [];
  loginResult: UserLogin;
  mobileQuery: MediaQueryList;
  private mobileQueryListener$: () => void;
  @ViewChild(MatSidenav, {static: false}) sidenav: MatSidenav;
  contactSearch$: Subject<string> = new Subject<string>();
  @ViewChild(MatMenuTrigger, {static: true}) menuTrigger: MatMenuTrigger;
  searchText = '';

  get currentAccount() {
    if(Global.currentAccount === null && this.router.url.toLowerCase() !== '/dashboard') {
      this.router.navigate(['dashboard']);
    }
    return Global.currentAccount;
  }


  constructor(
    @Inject(DOCUMENT) private document: Document,
    public userService: UserService,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    public st: ServerTimeService,
    private gs: GlobalService,
    private router: Router) {
    this.mobileQuery = media.matchMedia('(max-width: 1400px)');
    this.mobileQueryListener$ = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener$);
    this.loginResult = this.userService.UserLogin;
  }

  ngOnInit() {
    this.verifyUser();
    this.router.events.subscribe(() => {
      if (this.sidenav && this.isScreenSmall()) {
        this.sidenav.close();
      }
    });
    this.contactSearch$.next();
  }

  verifyUser() {
    const { token, applicationID } = this.route.snapshot.queryParams;
    console.log(token, applicationID);
    if (token && token != 'undefined') {
      let param = new ParamsHandler();
      param.addParam('GUID', token);
      param.addParam('ApplicationID', applicationID || 5);
      ApiRequest('POST', true)
        .controller('user')
        .action('login')
        .setBody(param)
        .call(this.gs)
        .subscribe(
          (resp) => {
            resp = normalizeResponse(resp);
            const currentUser = (resp as any).Data.User;
            currentUser.Token = token;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            this.router.navigate(['dashboard']);
          },
          (error) => {
            window.open(environment.HomeUrl, '_self');
          }
        );
      return;
    } else {
      this.userService.resetDataSet();
      localStorage.setItem('currentUser', '');
      localStorage.clear();
    }
  }

  isScreenSmall(): boolean {
    // if (this.sidenav && Global.menuItem.filter(m => m.location === 1).length === 0) {
    //   this.sidenav.close();
    // }
    // return this.mobileQuery.matches;
    return true;
  }

  userLogout() {
    this.userService.userLogout();
    this.router.navigate(['logout']);
    this.menuTrigger.closeMenu();
  }

  firstLetterName() {
    return this.loginResult?.FormalName?.split(' ').map( item => item.toUpperCase().substring(0, 1)).join(' ') || '☺';
  }

  goHome_onClick(){
    window.open(environment.HomeUrl + '?token=' + this.gs.currentUser.Token, '_self');
    // this.document.location.href = environment.HomeUrl;
  }

  account_onClick() {
    this.router.navigate(['dashboard']);
  }

}
