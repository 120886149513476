<h2 mat-dialog-title>
  <b>Name</b>
</h2>
<mat-dialog-content>
  <mat-card id="userProfile" class="mat-elevation-z10 content">
    <mat-card-content>
      <mat-tab-group [selectedIndex]="selectedTab" class="tabs" style="margin-top: -27px; ">
        <!-- View Profile -->
        <mat-tab>
          <div class="flex-container" style="margin-top: 25px;">
            <div style="float: left; ">
              <img class="profile-image" src="../../assets/images/profile-icon/User.png" alt="">
            </div>
            <div class="user-detail">
              <br>
              <div>
                <span>Full Name :</span>
                <span></span>
              </div>
              <div>
                <span>User Name :</span>
                <span></span>
              </div>
              <div>
                <span>User Email :</span>
                <span></span>
              </div>
              <div>
                <span>User Phone :</span>
                <span></span>
              </div>
              <hr>
              <div>
                <span>Account Name :</span>
                <span></span>
              </div>
              <div>
                <span>Role Name :</span>
                <span></span>
              </div>

            </div>
          </div>
        </mat-tab>
        <!-- Edit User -->
        <mat-tab>
          <div class="flex-container" style="margin-top: 25px;">
            <div style="float: left; ">
              <img class="profile-image" src="../../assets/images/profile-icon/User.png" alt="">
            </div>
            <div class="user-detail">
              <br>
              <div>
                <mat-form-field>
                  <input matInput [formControl]="formalName" placeholder="Full Name" [value]="user.FormalName">
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput [formControl]="userName" placeholder="User Name" [value]="user.UserName" required>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput [formControl]="email" placeholder="User Email" [value]="user.Email" required>
                </mat-form-field>
              </div>
              <div>
                <mat-form-field>
                  <input matInput [formControl]="cellPhone" placeholder="User Phone" [value]="user.CellPhone">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div *ngIf="errorDescription">
            <span class="error">{{errorDescription}}</span>
          </div>
        </mat-tab>
        <!-- Change Password -->
        <mat-tab>
          <div class="flex-container" style="margin-top: 25px;min-height: 300px;">
            <div style="float: left; ">
              <img class="profile-image" src="../../assets/images/profile-icon/password.png" alt="password change">
            </div>
            <div class="user-detail">
              <br>
              <div>
                <mat-form-field>
                  <input matInput [formControl]="oldPassword" type="Password" placeholder="Current Password" required>
                </mat-form-field> 
              </div>             
              <div>               
              <mat-form-field fxFlex="100%" class="full-width">
                <input (input)="truePass(passwordRef.value,confirmPasswordRef.value)" 
                    required matInput="password" placeholder="Repeat Password" type="Password" #passwordRef>

            </mat-form-field>
            <div class="trueLabel" >
                <div id="truePass" class="trigger"></div>
                <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 37 37" style="enable-background:new 0 0 37 37;" xml:space="preserve">
                    <path class="circ path" style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" 
                    d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                    />
                    <polyline class="tick path" style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;" 
                    points="11.6,20 15.9,24.2 26.4,13.8 " />
                </svg>
            </div>
                
              </div>
              
              <div>                
                <mat-form-field fxFlex="100%" class="full-width">
                  <input (input)="trueConfirm(passwordRef.value,confirmPasswordRef.value)" 
                    required matInput="password" placeholder="New Password" type="Password" #confirmPasswordRef >
                </mat-form-field>
  
                <div class="trueLabel">
                  <div id="trueConfirm" class="trigger"></div>
                  <svg version="1.1" id="tick" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 37 37" style="enable-background:new 0 0 37 37;" xml:space="preserve">
                    <path class="circ path" style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
                      d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
                    />
                    <polyline class="tick path" style="fill:none;stroke:darkslategrey;stroke-width:3;stroke-linejoin:round;stroke-miterlimit:10;"
                      points="11.6,20 15.9,24.2 26.4,13.8 " />
                  </svg>
                </div>
              </div>
              <label id="passLabel"></label>
            </div>
          </div>
          <div *ngIf="errorDescription">
            <span class="error">{{errorDescription}}</span>
          </div>
        </mat-tab>
        
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="cancelUpdate()">{{selectedTab == 0 ? 'Close' : 'Cancel'}}</button>
  <button mat-button (click)="editUser()" *ngIf="selectedTab == 0 || selectedTab == 1">{{selectedTab == 0 ? 'Edit' : 'Update'}}</button>
  <button mat-button (click)="changePassword(passwordRef.value,confirmPasswordRef.value)" *ngIf="selectedTab == 0 || selectedTab == 2">{{selectedTab == 0 ? 'Change Password' : 'Update'}}</button>
  <mat-spinner *ngIf="operationProcess" diameter="40" style="float: right;"></mat-spinner>
</mat-dialog-actions>