import { Pipe, PipeTransform } from '@angular/core';
import { Lib } from '@core/lib';

@Pipe({
  name: 'requestStatus'
})
export class RequestStatusPipe implements PipeTransform {
  transform(value: number): string {
    if ( value === 1) {
      return 'Schedule';
    } else if ( value === 2) {
      return 'Assign To TNC';
    } else if ( value === 3) {
      return 'Waitlist';
    }
  }
}
