<mat-card>
  <h1>Access Managment</h1>
  <div class="access-container">
    <ng-container *ngFor="let p of parentList; let first = first;">
      <label *ngIf="!first">/</label>
      <span class="access-header" >
        {{p.text}}
      </span>
    </ng-container>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  <div class="access-list">
    <mat-checkbox *ngFor="let item of accessList" [(ngModel)]="item.selected" class="item">
          {{item.AccessName}}
    </mat-checkbox>
  </div>

  <div class="parameter-action">
    <button mat-raised-button (click)="goBack_onClick()">Back</button>
    <button mat-raised-button (click)="selectAll_onClick()">Select All</button>
    <button mat-raised-button color="primary" (click)="save_onClick()">Save</button>
  </div>
</mat-card>
