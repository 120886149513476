import { Pipe, PipeTransform } from '@angular/core';
import { Lib } from '@core/lib';

@Pipe({
  name: 'TrueFalse'
})
export class TrueFalsePipe implements PipeTransform {
  transform(value: boolean): string {
    return Lib.trueFalse(value);
  }
}
