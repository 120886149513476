import { Component, OnInit } from '@angular/core';
import { ParamsHandler } from '@core/params-handler';
import { AccountInformation } from '@models/account-information';
import { GlobalService } from '@services/global.service';
import { ApiRequest, normalizeResponse } from '@services/request.service';
import { Global } from '@shared/Global';

@Component({
  selector: 'app-account-management',
  templateUrl: './aacount-management.component.html',
  styleUrls: ['./aacount-management.component.scss']
})
export class AccountManagementComponent implements OnInit {
  accountInfo: AccountInformation = new AccountInformation();
  generalInfoEditMode: 'View' | 'Edit' = 'View';
  logicalInfoEditMode: 'View' | 'Edit' = 'View';
  timeZoonList = [];
  constructor(public gs: GlobalService,) { }

  ngOnInit() {
    this.fetchTimeZoneList();
    this.accountInfo = Object.assign({}, Global.currentAccount);
    console.log(this.accountInfo);

  }

  generalInfoEdit_onClick(index) {
    if(index === 1) {
      this.generalInfoEditMode = this.generalInfoEditMode === 'View' ? 'Edit' : 'View';
    } else if(index === 2) {
      this.logicalInfoEditMode = this.logicalInfoEditMode === 'View' ? 'Edit' : 'View';
    }
  }


  updateGeneralInfo() {
    ApiRequest('POST')
    .schemaName(null)
    .controller('accessgroup')
    .action('itemdelete')
    .setBody(new ParamsHandler(this.accountInfo))
    .call(this.gs)
    .subscribe((resp) => {
      resp = normalizeResponse(resp);
      if (resp.Success == true) {
        this.gs.toaster.open({type: 'success', text: 'Delete Successful.' });
      } else {
        this.gs.toaster.open({type: 'danger', text: resp.Message});
      }
    });
  }

  fetchTimeZoneList() {
    return
    ApiRequest('GET')
      .schemaName(null)
      .controller('timezone')
      .action('list')
      .call(this.gs).subscribe( (resp: any) => {
        this.timeZoonList = resp.Data.TimeZones;
      });
  }


}
