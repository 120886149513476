 <!-- <tree-control [dataSource]="dataSource">
    <tree-node *treeNodeDef="let node; let isChecked = checked">
		ddd
		<span [ngClass]="{ 'node__label--checked': isChecked }">{{ node.label }}</span>
	</tree-node>
</tree-control> -->


 <!-- <dm-tree [dataSource]="dataList" (event)="tree_onEvent($event)">
	<tree-node *treeNodeDef="let node; let isChecked = checked">
		<a>Node</a>
		<button mat-icon-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{node: node}" class="node-icon">
			<mat-icon class="group">groups</mat-icon>
		</button>
		<button mat-icon-button (click)="test(node?.data, isChecked)">{{node?.data.name}}</button>
	</tree-node>
	<tree-node *treeLeafNodeDef="let node; let isChecked = checked">
		<a>Leaf</a>
		<button mat-button (click)="test(node?.data, isChecked)">{{node?.data.name}}</button>
	</tree-node>
	<tree-node *treeNewNodeDef="let node; let isChecked = checked">
		<a>New</a>
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>{{node?.data.name}}</mat-label>
			<input matInput type="text" [(ngModel)]="node.data.name">
			<button *ngIf="node.data.name" matSuffix mat-icon-button aria-label="Clear" (click)="node.data.name=''">
			  <mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</tree-node>
	<tree-node *treeEditNodeDef="let node; let isChecked = checked">
		<a>Edit</a>
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>{{node?.data.name}}</mat-label>
			<input matInput type="text" [(ngModel)]="node.data.name">
			<button *ngIf="node.data.name" matSuffix mat-icon-button aria-label="Clear" (click)="node.data.name=''">
			  <mat-icon>close</mat-icon>
			</button>
		  </mat-form-field>
	</tree-node>
	<tree-node *treeCustomNodeDef="let node; let isChecked = checked">
		<a>Custom</a>
		<mat-form-field class="example-form-field" appearance="fill">
			<mat-label>{{node?.data.name}}</mat-label>
			<input matInput type="text" [(ngModel)]="node.data.name">
			<button *ngIf="node.data.name" matSuffix mat-icon-button aria-label="Clear" (click)="node.data.name=''">
			  <mat-icon>close</mat-icon>
			</button>
		</mat-form-field>
	</tree-node>
</dm-tree>
<button mat-button (click)="btn_onClick()"> Add Node </button>
<mat-menu #menu="matMenu">
	<ng-template matMenuContent let-node="node">
	  <button mat-menu-item (click)="addNewItem(node)">
		<mat-icon>add</mat-icon>
		<span>New Access Group</span>
	  </button>
	  <button mat-menu-item (click)="editItem(node)">
		<mat-icon>edit</mat-icon>
		<span>Edit Access Group</span>
	  </button>
	  <button mat-menu-item (click)="deleteItem(node)">
		<mat-icon>delete</mat-icon>
		<span>Delete Access</span>
	  </button>
	  <button mat-menu-item (click)="manageAccessGroup(node)">
		<mat-icon>group_add</mat-icon>
		<span>Manage Access</span>
	  </button>
	</ng-template>
  </mat-menu>   -->


  <!-- <div class="stage">

    <div class="icon">

    </div>

  </div> -->

<app-master-page></app-master-page>


<!-- <router-outlet></router-outlet> -->

<!-- <app-school-otp></app-school-otp> -->
